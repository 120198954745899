import { TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';

const TableCellHeaderSummary = styled(TableCell)(({ theme }) => ({
  paddingTop: '10px',
  paddingBottom: '10px',
  fontFamily: 'Barlow',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '18.2px',
  color: theme.palette.mode === 'dark' ? '#fff' : '#3C4043',
  textAlign: 'left',
  minWidth: '60px',
}));

export default TableCellHeaderSummary;
