import LandUseTableArea from './LandUseTableArea';
import LandUseTableDistance from './LandUseTableDistance';

export const styles = {
  userListTitle: {
    py: '10px',
    fontFamily: 'Barlow',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '18.2px',
    textAlign: 'center',
  },
  clientUpdateTime: {
    textAlign: 'center',
    px: '16px',
  },
};

const LandUseTable = () => {
  return (
    <>
      <LandUseTableArea />
      <LandUseTableDistance />
    </>
  );
};

export default LandUseTable;
