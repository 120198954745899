import { QUERY_KEY } from 'constants/constants';
import { get, isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getAnalyticBoxPlot,
  getAnalyticPolygon,
  getStandCountAnalytic,
  updateInterval,
} from 'services/analytics/apiAnalyticsConfig.services';
import { sendGetDataAnalysisByLevelIdDate } from 'services/clients/apiClient.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeChartDataGapPreview,
  changeDataGapAnalytics,
  changeDataGapInterval,
  changeDataPolygonGapAnalytics,
} from 'store/slices/map-view/gapAnalytics';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { TYPE_ANALYTICS_MAP_VIEW } from '../../../../../../common/defines/constants';
import { getChartsGap } from '../../../../../../services/analytics/apiAnalyticsData.services';
import { gapAnalyticsSelector } from '../../../../../../store/slices/map-view/gapAnalytics';
import SkeletonCharts from '../../../../../Common/SkeletonCharts';
import { GapLayer } from '../../Layer/Gap';
import { SwipeableViewsCustom } from '../../SwipeableViewsCustom';
import ChartsAnalytics from '../ChartsAnalytics/ChartsAnalytics';

export const GapAnalytics = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const [standCountId, setStandCountId] = useState('');

  const { analyticId, isDefaultInterval, dateAnalyticSelected, levelId } = useAppSelector(mapViewSelector);
  const { dataGapInterval, dataGapPolygon, chartDataGapPreview } = useAppSelector(gapAnalyticsSelector);

  const { data: dataGapBoxPlot, isLoading } = useQuery(
    [QUERY_KEY.GAP_BOX_PLOT, analyticId],
    () => getAnalyticBoxPlot(analyticId || '', 'gap'),
    { enabled: !!analyticId }
  );

  const [currentPage, setCurrentPage] = useState(1);

  useQuery(
    [QUERY_KEY.DATA_ANALYSIS, levelId, dateAnalyticSelected],
    () => sendGetDataAnalysisByLevelIdDate(levelId || '', dateAnalyticSelected || ''),
    {
      enabled: !!dateAnalyticSelected && !!levelId,
      onSuccess: (data: any) => {
        setStandCountId(data?.data?.find((item: any) => item?.name === 'Stand Count')?._id);
      },
    }
  );

  useQuery(
    [QUERY_KEY.GAP_POLYGON, analyticId, currentPage],
    () => getAnalyticPolygon(analyticId || '', 'gap', currentPage),
    {
      enabled: !!analyticId,
      onSuccess: (data: any) => {
        if (data.data?.nextPage && data.data.nextPage !== currentPage) {
          setCurrentPage(data.data?.nextPage);
        }
        if (data.data?.docs) {
          dispatch(changeDataPolygonGapAnalytics([...dataGapPolygon, ...data.data?.docs]));
        }
      },
    }
  );

  useQuery([QUERY_KEY.STAND_COUNT_ANALYSIS, standCountId, dataGapInterval], () => getStandCountAnalytic(standCountId), {
    enabled: !!standCountId && !!dataGapInterval,
    onSuccess: (mainData) => {
      dispatch(changeDataGapAnalytics(mainData?.data || []));
    },
  });
  const { data: chartsData } = useQuery(
    [QUERY_KEY.CHARTS_GAP_ANALYTIC, isDefaultInterval],
    () => getChartsGap(analyticId || '', isDefaultInterval),
    {
      enabled: !!analyticId,
      onSuccess: (data) => {
        dispatch(
          changeDataGapInterval({
            ...data.data?.intervalLimit,
            range: data.data?.intervalLimit.range.map((range: any, index: number) => ({
              ...range,
              visible: get(dataGapInterval, `range[${index}].visible`, true),
            })),
          })
        );
      },
    }
  );

  const handleUpdateIntervals = useMutation(
    (data: any) => updateInterval(chartsData?.data?.intervalLimit?._id || '', data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.CHARTS_GAP_ANALYTIC).then(() => { });
      },
    }
  );

  const dataBoxPlot = useMemo(() => {
    if (!dataGapBoxPlot?.data) return [];
    return dataGapBoxPlot.data;
  }, [dataGapBoxPlot]);

  return (
    <SwipeableViewsCustom
      analyticName={TYPE_ANALYTICS_MAP_VIEW.GAP}
      analyticTab={
        <>
          {!isLoading ? (
            <>
              {!isEmpty(chartsData?.data?.chartData) && (
                <ChartsAnalytics
                  dataChart={chartDataGapPreview ? chartDataGapPreview?.chartData : chartsData?.data?.chartData || []}
                  title={TYPE_ANALYTICS_MAP_VIEW.GAP}
                  getCharts={getChartsGap}
                  intervalRange={
                    chartDataGapPreview
                      ? chartDataGapPreview.intervalLimit.range
                      : chartsData?.data?.intervalLimit?.range || []
                  }
                  handleUpdateIntervals={handleUpdateIntervals}
                  queryKey={QUERY_KEY.CHARTS_GAP_ANALYTIC}
                  dataBoxPlot={dataBoxPlot}
                  changeChartDataPreview={changeChartDataGapPreview}
                  showXAxis={true}
                />
              )}
            </>
          ) : (
            <SkeletonCharts count={1} />
          )}
        </>
      }
      layerTab={<GapLayer labelCard={TYPE_ANALYTICS_MAP_VIEW.GAP} />}
      isShowDownloadButton={!isLoading && !isEmpty(chartsData?.data?.chartData)}
    />
  );
};
