import { Box, useTheme } from '@mui/material';
import { FC } from 'react';
import { Navigation, Pagination } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { IIssue } from '../../../interfaces';

interface SwiperBoxProps {
  isFullSize?: boolean;
  size: { width: number; height: number };
  item: IIssue;
  setIsShowFullImageModal?: (arg: boolean) => void;
  setActiveIndex?: (arg: number) => void;
  activeIndex?: number;
}

const SwiperBox: FC<SwiperBoxProps> = ({
  isFullSize = false,
  size,
  item,
  activeIndex = 0,
  setIsShowFullImageModal,
  setActiveIndex,
}) => {
  const theme = useTheme();

  const handleClickImage = () => {
    if (setIsShowFullImageModal) {
      setIsShowFullImageModal(true);
    }
  };
  return (
    <Box
      sx={{
        '& .swiper-button-prev:after, & .swiper-button-next:after': {
          fontSize: '22px',
          fontWeight: '900',
          color: theme.palette.primary.main,
        },
        '& .swiper-pagination': {
          transform: isFullSize ? 'translate3d(0, 0px, 0)' : 'translate3d(0, 8px, 0)',
        },
        '& .swiper-pagination-bullet': {
          background: theme.palette.primary.main,
          minWidth: '20px',
          width: '32px',
          height: '2px',
          borderRadius: '2px',
        },
      }}>
      <Swiper
        pagination={{ type: 'bullets' }}
        style={{ ...size }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
        initialSlide={activeIndex}
        onRealIndexChange={(ele) => setActiveIndex && setActiveIndex(ele.activeIndex)}>
        {item.images.map((element, i) => (
          <SwiperSlide key={i}>
            <img
              style={{
                ...size,
                borderRadius: '8px',
                objectFit: isFullSize ? 'contain' : 'cover',
              }}
              src={element}
              alt="geometric"
              onClick={() => handleClickImage()}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default SwiperBox;
