import SwapVertIcon from '@mui/icons-material/SwapVert';
import {
  Box,
  InputLabel,
  SvgIcon,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import { AscIcon, DescIcon } from 'assets/icons';
import { SettingUnitName } from 'common/defines/clients';
import { ITableHeaders, TSortOrder } from 'common/defines/constants';
import { defaultClientUnitSetting } from 'common/dummy/dummyClients';
import { QUERY_KEY } from 'constants/constants';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getClientSettingsById } from 'services/clients/apiClient.services';
import { useAppSelector } from 'store/hooks';
import { clientSelector } from 'store/slices/clientSlice';
import { clientLengthUnitMeasurement } from '../../IssuesTab/utils';
import TableCellHeaderSummary from '../components/TableCellHeaderSummary';
import TableCellSummary from '../components/TableCellSummary';

interface IRow {
  landUse: string;
  length: number;
}

enum TableKeyEnum {
  NAME = 'Name',
  LENGTH = 'Length',
}

const LandUseTableDistance = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { allLocationCropType } = useAppSelector(clientSelector);
  const { clientId } = useParams();

  const initialTableHeaders = [
    { key: TableKeyEnum.NAME, label: 'trans.main_category', sortOrder: null },
    { key: TableKeyEnum.LENGTH, label: 'trans.length', sortOrder: null },
  ];

  const [tableHeaders, setTableHeaders] = useState<ITableHeaders<TableKeyEnum>[]>(initialTableHeaders);
  const [sortInfo, setSortInfo] = useState<{ key: TableKeyEnum; sortOrder: TSortOrder }>({
    key: TableKeyEnum.NAME,
    sortOrder: null,
  });

  const { data: clientSetting } = useQuery(
    [QUERY_KEY.CLIENT_SETTINGS_BY_ID, clientId],
    () => getClientSettingsById(clientId || ''),
    {
      enabled: !!clientId,
    }
  );

  const lengthSetting = useMemo(() => {
    return (
      clientSetting?.unitSetting?.find((data: any) => data.name === SettingUnitName.LENGTH) ||
      defaultClientUnitSetting[1]
    );
  }, [clientSetting]);

  const tableLandUseDistance = useMemo(() => {
    if (allLocationCropType && allLocationCropType.length > 0) {
      const mergeArray = allLocationCropType.reduce((pre: any, curent: any) => {
        return pre.concat(curent.landUseAnalysisSummary);
      }, []);
      const filteredArray: IRow[] = mergeArray.filter((item: IRow) => item.length);
      const reducedArray = filteredArray.reduce<IRow[]>((acc, cur) => {
        const existed = acc.find((ele: IRow) => ele.landUse === cur.landUse);
        if (existed) {
          existed.length += cur.length;
        } else {
          acc.push({ ...cur });
        }
        return acc;
      }, []);

      const sortFunction = (first: IRow, second: IRow) => {
        const { key, sortOrder } = sortInfo;
        if (key === TableKeyEnum.NAME) {
          return sortOrder === 'ASC'
            ? first.landUse?.localeCompare(second.landUse)
            : second.landUse?.localeCompare(first.landUse);
        } else if (key === TableKeyEnum.LENGTH) {
          return sortOrder === 'ASC' ? first.length - second.length : second.length - first.length;
        }
        return 0;
      };

      const sortedArray = reducedArray.sort((a, b) => sortFunction(a, b));

      if (sortedArray && sortedArray.length > 0) {
        return (
          <>
            {sortedArray.map((item: any, index: number) => {
              return (
                <TableRow key={index}>
                  <TableCellSummary component="td">{item.landUse}</TableCellSummary>
                  <TableCellSummary component="td">
                    {clientLengthUnitMeasurement(item.length, lengthSetting)} {`${lengthSetting.unit}`}
                  </TableCellSummary>
                </TableRow>
              );
            })}
          </>
        );
      }
    }
  }, [allLocationCropType, lengthSetting, sortInfo]);

  const handleSortTable = (key: TableKeyEnum, sortOrder: TSortOrder) => {
    let newSortOrder: TSortOrder = null;
    if (!sortOrder) newSortOrder = 'DESC';
    if (sortOrder === 'ASC') newSortOrder = 'DESC';
    if (sortOrder === 'DESC') newSortOrder = 'ASC';
    setSortInfo({ key, sortOrder: newSortOrder });

    const newTableHeaders = tableHeaders.map((header) =>
      header.key === key ? { ...header, sortOrder: newSortOrder } : { ...header, sortOrder: null }
    );
    setTableHeaders(newTableHeaders);
  };

  const renderSortIcon = (sortOrder: TSortOrder) => {
    switch (sortOrder) {
      case 'ASC':
        return DescIcon;
      case 'DESC':
        return AscIcon;
      default:
        return SwapVertIcon;
    }
  };

  return (
    <Box sx={{ py: 2 }}>
      <InputLabel sx={{ color: (theme) => theme.palette.primary.main }}>{`${t('analytic.land_use')} (${t(
        'trans.distance'
      )})`}</InputLabel>
      <TableContainer>
        <Table>
          <TableHead>
            {
              <TableRow
                component="tr"
                sx={{
                  backgroundColor: theme.palette.background.paper,
                }}>
                {tableHeaders.map((item) => (
                  <TableCellHeaderSummary component="th" key={item.key}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      {t(item.label)}
                      <SvgIcon
                        component={renderSortIcon(item.sortOrder)}
                        inheritViewBox
                        sx={{ fontSize: '14px', cursor: 'pointer' }}
                        onClick={() => handleSortTable(item.key, item.sortOrder)}
                      />
                    </Box>
                  </TableCellHeaderSummary>
                ))}
              </TableRow>
            }
          </TableHead>
          <TableBody
            sx={{
              pt: 0,
              flexDirection: 'column',
              minHeight: '510px',
              maxHeight: '510px',
              overflowY: 'scroll',
              '-ms-overflow-style': 'none' /* IE and Edge */,
              scrollbarWidth: 'none' /* Firefox */,
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}>
            {tableLandUseDistance}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default LandUseTableDistance;
