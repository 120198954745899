import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  styled,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import WaitingPageAPI from 'components/Common/WaitingAPI';
import { TabPanel } from 'components/MapView/TabPanel';
import { addYears, endOfDay, endOfYear, isSameDay, startOfYear } from 'date-fns';
import ReactECharts from 'echarts-for-react';
import { SyntheticEvent, useMemo, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { DateRangePicker, defaultStaticRanges } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useMutation } from 'react-query';
import SwipeableViews from 'react-swipeable-views';
import { getFilterMultiChart } from 'services/clients/apiClient.services';
import { AuditSingleLogStyle } from './AuditStyle';
import TableDatalogs from './TableDatalogs';

const a11yProps = (index: number) => {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
  };
};

const TabStyled = styled(Tab)(({ theme }) => ({
  textTransform: 'unset',
  color: theme.palette.color.grey3,
  fontWeight: 500,
  fontSize: '12px',
  '&.Mui-selected': {
    color: theme.palette.color.black1,
  },
}));
const tabMenu = [
  {
    id: 0,
    label: 'Chart View',
  },
  {
    id: 1,
    label: 'Data Table View',
  },
];

const FilterAuditTrail = (props: any) => {
  const { open, onClose } = props;
  const classes = AuditSingleLogStyle();
  const [dataFilter, setDataFilter] = useState<Array<any>>([]);
  const [tabNumber, setTabNumber] = useState<number>(0);
  const [state, setState] = useState([
    {
      startDate: startOfYear(new Date()),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const theme = useTheme();

  const handleChangeTab = (_event: SyntheticEvent<Element, Event>, newValue: any) => {
    setTabNumber(newValue);
  };

  const filterDataSend = useMemo(() => {
    return {
      startDate: `${state[0].startDate.getFullYear()}-${
        state[0].startDate.getMonth() + 1
      }-${state[0].startDate.getDate()}`,
      endDate: `${state[0].endDate.getFullYear()}-${state[0].endDate.getMonth() + 1}-${state[0].endDate.getDate()}`,
    };
  }, [state]);

  const handleSendFilter = useMutation((data: { startDate: any; endDate: any }) => getFilterMultiChart(data), {
    onSuccess: (data) => {
      setDataFilter(data.data);
    },
    onError: (err) => {
      console.log('err', err);
    },
  });

  const handleSubmitFilter = () => {
    handleSendFilter.mutate({
      startDate: filterDataSend.startDate,
      endDate: filterDataSend.endDate,
    });
  };

  const handleClose = () => {
    onClose();
  };

  const dataChart = useMemo(() => {
    if (!dataFilter)
      return {
        dateLog: [],
        countLog: [],
        nameLog: [],
      };
    const userLogList: any[] = [];
    const dateLogList: any[] = [];
    // foreach date
    dataFilter.forEach((dataCount: any, index: number) => {
      // foreach log user
      dateLogList.push(dataCount.date);
      dataCount.array.forEach((dt: any) => {
        const duplicateDataIndex = userLogList.findIndex((i) => i.id === dt?.record[0]?.user?._id);
        if (duplicateDataIndex > -1) {
          userLogList[duplicateDataIndex].data[index] = dt.count;
          return;
        }
        const userLogData: {
          name: string;
          id: string;
          type: string;
          data: any[];
          emphasis: any;
        } = {
          name: `${dt?.record[0]?.user?.name} (${dt?.record[0]?.user?.email})`,
          id: dt.record[0]?.user?._id,
          data: Array(dataFilter.length).fill(0),
          type: dataFilter.length === 1 ? 'bar' : 'line',
          emphasis: { focus: 'series' },
        };
        userLogData.data[index] = dt.count;
        userLogList.push(userLogData);
      });
    });
    return {
      dateLog: dateLogList,
      countLog: userLogList,
      nameLog: userLogList.map((data: any) => data.name),
    };
  }, [dataFilter]);

  const onEvents = useMemo(() => {
    return {
      legendselectchanged: (params: any) => {
        console.log('legendselectchanged', params);
      },
    };
  }, []);

  // Multi Chart Data
  const options = useMemo(() => {
    return {
      title: {
        text: 'Login Time',
        textStyle: {
          color: theme.palette.mode === 'dark' ? '#fff' : '#000',
        },
      },
      tooltip: {
        trigger: 'axis',
        responsive: true,
        position: (pos: any, params: any, el: any, elRect: any, size: any) => {
          var obj = { top: 40 } as any;
          obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 10;
          return obj;
        },
        backgroundColor: theme.palette.mode === 'dark' ? '#3C4043' : '#fff',
        textStyle: {
          color: theme.palette.mode === 'dark' ? '#fff' : '#000',
        },
      },
      legend: {
        // get user here
        data: dataChart.nameLog,
        type: 'scroll',
        bottom: 10,
        textStyle: {
          color: theme.palette.mode === 'dark' ? '#fff' : '#000',
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '10%',
        containLabel: true,
      },
      toolbox: {
        show: true,
        feature: {
          dataZoom: {
            yAxisIndex: 'none',
          },
          // dataView: {
          //   readOnly: false,
          //   optionToContent: (opt: any) => {
          //     var axisData = opt.xAxis[0].data;
          //     var series = opt.series;
          //     var table = '<table style="width:100%;text-align:center"><tbody><tr>' + '<td>Date</td>';
          //     for (var i = 0, l = series.length; i < l; i++) {
          //       table += '<td>' + series[i].name + '</td>';
          //     }
          //     table += '</tr>';
          //     for (var j = 0, k = axisData.length; j < k; j++) {
          //       table += '<tr>' + '<td>' + axisData[j] + '</td>';
          //       for (var m = 0, n = series.length; m < n; m++) {
          //         table += '<td>' + series[m].data[j] + '</td>';
          //       }
          //       table += '</tr>';
          //     }
          //     table += '</tbody></table>';
          //     return table;
          //   },
          //   title: 'Data View',
          //   lang: ['Data View', 'Refresh', 'Close'],
          // },
          magicType: { type: ['line', 'bar'] },
          saveAsImage: {},
        },
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        // get Date here
        data: dataChart.dateLog,
      },
      yAxis: {
        type: 'value',
      },
      series: dataChart.countLog,
    };
  }, [dataChart.countLog, dataChart.dateLog, dataChart.nameLog, theme.palette.mode]);

  return (
    <Dialog
      fullScreen
      fullWidth
      onClose={handleClose}
      open={open}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            overflowY: 'scroll',
            backgroundColor: theme.palette.background.default,
            '-ms-overflow-style': 'none' /* IE and Edge */,
            scrollbarWidth: 'none' /* Firefox */,
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          },
        },
      }}>
      <DialogTitle className={classes.dialogTitle}>Filter Audit Trail</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          p: 0,
          position: 'absolute',
          right: 8,
          top: 8,
          '.MuiSvgIcon-root': {
            width: '16px',
            height: '16px',
          },
          color: (theme) => theme.palette.color.grey5,
        }}>
        <CloseIcon />
      </IconButton>
      <Box className={classes.boxContainer}>
        {/* Form Filter */}
        <Box className={classes.boxFilter}>
          <Typography className={classes.boxLeft}>
            <DateRangePicker
              onChange={(item) => setState([item.selection] as any)}
              moveRangeOnFirstSelection={false}
              ranges={state}
              direction="horizontal"
              rangeColors={[theme.palette.primary.main, theme.palette.primary.contrastText]}
              color={theme.palette.primary.main}
              fixedHeight
              staticRanges={[
                ...defaultStaticRanges,
                {
                  label: 'This year',
                  range: () => ({
                    startDate: startOfYear(new Date()),
                    endDate: endOfDay(new Date()),
                  }),
                  isSelected(range: any) {
                    const definedRange: any = this.range();
                    return (
                      isSameDay(range.startDate, definedRange.startDate) &&
                      isSameDay(range.endDate, definedRange.endDate)
                    );
                  },
                },
                {
                  label: 'Last year',
                  range: () => ({
                    startDate: startOfYear(addYears(new Date(), -1)),
                    endDate: endOfYear(addYears(new Date(), -1)),
                  }),
                  isSelected(range: any) {
                    const definedRange: any = this.range();
                    return (
                      isSameDay(range.startDate, definedRange.startDate) &&
                      isSameDay(range.endDate, definedRange.endDate)
                    );
                  },
                },
              ]}
            />
          </Typography>
          <DialogActions>
            <Button variant="contained" fullWidth className={classes.saveButton} onClick={() => handleSubmitFilter()}>
              View
            </Button>
          </DialogActions>
        </Box>
        {/* Pagination + Chart */}
        <Box className={classes.boxRight}>
          <Tabs value={tabNumber} onChange={handleChangeTab} variant="fullWidth" className={classes.tabSwipeBtn}>
            {tabMenu.map((item) => (
              <TabStyled
                key={item.id}
                label={item.label}
                {...a11yProps(item.id)}
                sx={{
                  fontSize: '16px',
                  color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                  '&.Mui-selected': {
                    color: theme.palette.primary.main,
                  },
                }}
              />
            ))}
          </Tabs>
          <SwipeableViews
            axis={'x-reverse'}
            index={tabNumber}
            style={{
              height: '100%',
            }}>
            <TabPanel
              isAnalyticsTab
              style={{
                padding: '20px 0',
              }}
              value={tabNumber}
              index={0}>
              <Scrollbars style={{ height: 'calc(100vh - 190px)', width: '100%' }} autoHide>
                {/* MultiChart */}
                {!handleSendFilter.isLoading ? (
                  <ReactECharts
                    option={options}
                    style={{
                      maxHeight: '780px',
                      width: '100%',
                      minHeight: '500px',
                      height: '100vh',
                    }}
                    onEvents={onEvents}
                    theme={theme.palette.mode === 'dark' ? 'dark' : 'light'}
                  />
                ) : (
                  <WaitingPageAPI />
                )}
              </Scrollbars>
            </TabPanel>
            <TabPanel
              style={{
                padding: '20px 0',
              }}
              isAnalyticsTab
              value={tabNumber}
              index={1}>
              <TableDatalogs data={dataFilter} date={dataChart.dateLog} />
            </TabPanel>
          </SwipeableViews>
        </Box>
      </Box>
    </Dialog>
  );
};

export default FilterAuditTrail;
