import { SettingUIAnalysis, SettingUIIconFavorite, SettingUIName, SettingUISizePointAnalysis } from './clients';

export const VALIDATION_REQUIRED = {
  required: 'Please fill in this field!',
  companyName: 'Please provide the company name',
  email: 'Please provide an email',
  inValidEmail: 'Email is invalid',
  ssm: 'Please provide a valid number',
  address: 'Please provide the current address',
  contactNumber: 'Please provide a contact number',
  avatar: 'Please provide an avatar',
  cropType: 'Please select a type',
  fullName: 'Please provide full name',
  propertyName: 'Please provide property name ',
  propertyType: 'Please select a property type',
  valueOptions: 'Please provide at least one value option',
  inputLength: 'Please provide input length',
};

export const DIFFERENT_COLOR = [
  '#ff0505',
  '#05FF12',
  '#ffe119',
  '#4363d8',
  '#f58231',
  '#911eb4',
  '#46f0f0',
  '#f032e6',
  '#bcf60c',
  '#fabebe',
  '#008080',
  '#e6beff',
  '#9a6324',
  '#fffac8',
  '#800000',
  '#aaffc3',
  '#808000',
  '#ffd8b1',
  '#000075',
  '#808080',
  '#e055c4',
  '#F3E5AB',
];

export enum TypeRaster {
  RGB = 'RGB',
  RGB_MULTI_SPECTRAL = 'RGB Multispectral',
}

export enum TYPE_ANALYTICS_MAP_VIEW {
  LAND_USE_ANALYSIS = 'Land Use',
  CROP_COVERAGE = 'Crop Coverage',
  STAND_COUNT = 'Stand Count',
  WATER_LOGGING = 'Water Logging',
  CROWN_AREA = 'Crown Area',
  GAP = 'Gap',
  CIRCUMFERENCE_ANALYSIS = 'Circumference',
  TREE_HEIGHT = 'Tree Height',
  CO2_SEQUESTRATION = 'CO2 Sequestration',
  BOUNDING_BOX = 'Bounding Box',
  WEED_INVASION = 'Weed Invasion',
  VACANT_AREA = 'Vacant Area',
  TILLER_DENSITY = 'Tiller Density',
  SOIL_WATER_CONTENT_FIELD = 'Soil Water Content Field',
  SOIL_EROSION_FIELD = 'Soil Erosion Field',
  LEAF_PHENOLOGY_FIELD = 'Leaf Phenology Field',
  ELEVATION_FIELD = 'Elevation Field',
  SLOPE_FIELD = 'Slope Field',
  CUSTOM_FIELD = 'Custom Field',
  SOIL_MOISTURE_CONTENT = 'Soil Moisture Content',
  FISSURE = 'Fissure',
  WATERSHED_BASIN = 'Watershed Basin',
  VEGETATION_ENCROACHMENT = 'Vegetation Encroachment',

  STRESS_TREE = 'Stress Tree',
  STRESS_FIELD = 'Stress Field',
  WATER_UPTAKE_TREE = 'Water Uptake Tree',
  WATER_UPTAKE_FIELD = 'Water Uptake Field',
  PLANT_HEALTH_TREE = 'Plant Health Tree',
  PLANT_HEALTH_FIELD = 'Plant Health Field',
  VIGOR_TREE = 'Vigor Tree',
  VIGOR_FIELD = 'Vigor Field',
  CHLOROPHYLL_TREE = 'Chlorophyll Tree',
  CHLOROPHYLL_FIELD = 'Chlorophyll Field',
  TREE_TILT_FALLEN = 'Tree Tilt Fallen',
}

export enum FILE_TYPE {
  CHLOROPHYLL_FILE = 'CHLOROPHYLL_FILE',
  CHLOROPHYLL_PADDY_GEOJSON = 'CHLOROPHYLL_PADDY_GEOJSON',
  CIRCUMFERENCE_DATA_FILE = 'CIRCUMFERENCE_DATA_FILE',
  TREE_HEIGHT_FILE = 'TREE_HEIGHT_FILE',
  CO2_SEQUESTRATION_FILE = 'CO2_SEQUESTRATION_FILE',
  CROP_NONCROP_GEOJSON = 'CROP_NONCROP_GEOJSON',
  CROWN_AREA_FILE = 'CROWN_AREA_FILE',
  GAP_FILE = 'GAP_FILE',
  INTERVALS_FILE = 'INTERVALS_FILE',
  LINE_STRING_FILE = 'LINE_STRING_FILE',
  POLYGON_FILE = 'POLYGON_FILE',
  POINT_FILE = 'POINT_FILE',
  PLANTATION_BOUNDARY_GEOJSON = 'PLANTATION_BOUNDARY_GEOJSON',
  PLANT_HEALTH_GEOJSON = 'PLANT_HEALTH_GEOJSON',
  STAND_COUNT_FILE = 'STAND_COUNT_FILE',
  STAND_COUNT_CROP_BOUNDARY = 'STAND_COUNT_CROP_BOUNDARY',
  STRESS_FILE = 'STRESS_FILE',
  TILLER_DENSITY_GEOJSON = 'TILLER_DENSITY_GEOJSON',
  VACANT_AREA_GEOJSON = 'VACANT_AREA_GEOJSON',
  VIGOR_FILE = 'VIGOR_FILE',
  VIGOR_PADDY_GEOJSON = 'VIGOR_PADDY_GEOJSON',
  WATER_UPTAKE_FILE = 'WATER_UPTAKE_FILE',
  WEED_INVASION_GEOJSON = 'WEED_INVASION_GEOJSON',
  PLAN_HEALTH_OILPALM_FILE = 'PLAN_HEALTH_OILPALM_FILE',
  STRESS_FIELD_GEOJSON = 'STRESS_FIELD_GEOJSON',
  WATER_UPTAKE_FIELD_GEOJSON = 'WATER_UPTAKE_FIELD_GEOJSON',
  SOIL_WATER_CONTENT_FIELD_GEOJSON = 'SOIL_WATER_CONTENT_FIELD_GEOJSON',
  SOIL_EROSION_FIELD_GEOJSON = 'SOIL_EROSION_FIELD_GEOJSON',
  LEAF_PHENOLOGY_FIELD_GEOJSON = 'LEAF_PHENOLOGY_FIELD_GEOJSON',
  ELEVATION_FIELD_GEOJSON = 'ELEVATION_FIELD_GEOJSON',
  SLOPE_FIELD_GEOJSON = 'SLOPE_FIELD_GEOJSON',
  CUSTOM_FIELD_GEOJSON = 'CUSTOM_FIELD_GEOJSON',
  SOIL_MOISTURE_CONTENT_GEOJSON = 'SOIL_MOISTURE_CONTENT_GEOJSON',
  FISSURE_GEOJSON = 'FISSURE_GEOJSON',
  WATERSHED_BASIN_GEOJSON = 'WATERSHED_BASIN_GEOJSON',
  VEGETATION_ENCROACHMENT_GEOJSON = 'VEGETATION_ENCROACHMENT_GEOJSON',
  TREE_TILT_FALLEN = 'TREE_TILT_FALLEN',
}

export const mapFileLabel = {
  CHLOROPHYLL_FILE: 'Chlorophyll File', //chlorophyll-analysis
  CHLOROPHYLL_PADDY_GEOJSON: 'Chlorophyll Field Geojson', //chlorophyll-paddy-analysis
  CIRCUMFERENCE_DATA_FILE: 'Circumference Data File', //circumference-analysis
  TREE_HEIGHT_FILE: 'Tree Height File',
  CO2_SEQUESTRATION_FILE: 'Co2 Sequestration File',
  CROP_NONCROP_GEOJSON: 'Crop Noncrop Geojson', //crop-cover
  CROWN_AREA_FILE: 'Crown Area File', //crown-area-analysis
  GAP_FILE: 'Gap File', //gap-analysis
  INTERVALS_FILE: 'Intervals File', //interval-limit-analysis
  LINE_STRING_FILE: 'Line String File', //land-use-analysis
  POLYGON_FILE: 'Polygon File', //land-use-analysis
  POINT_FILE: 'Point File', //land-use-analysis
  PLANTATION_BOUNDARY_GEOJSON: 'Plantation Boundary Geojson', //crop-cover/downloadPlantationData
  PLANT_HEALTH_GEOJSON: 'Plant Health Geojson', //plant-health-analysis
  STAND_COUNT_FILE: 'Stand Count File', //stand-count-analysis
  STAND_COUNT_CROP_BOUNDARY: 'Stand Count Boundary',
  STRESS_FILE: 'Stress File', //stress-analysis
  TILLER_DENSITY_GEOJSON: 'Tiller Density Geojson', //tiller-density-analysis
  VACANT_AREA_GEOJSON: 'Vacant Area Geojson', //vacant-area-analysis
  VIGOR_FILE: 'Vigor File', //vigor-analysis
  VIGOR_PADDY_GEOJSON: 'Vigor Field Geojson', //vigor-paddy-analysis
  WATER_UPTAKE_FILE: 'Water Uptake File', //water-uptake-analysis
  WEED_INVASION_GEOJSON: 'Weed Invasion Geojson', //weed-invasion-analysis
  PLAN_HEALTH_OILPALM_FILE: 'Plant Health File', //plant-health-oilpalm-analysis
  STRESS_FIELD_GEOJSON: 'Stress Field Geojson',
  WATER_UPTAKE_FIELD_GEOJSON: 'Water Uptake Field Geojson',
  SOIL_WATER_CONTENT_FIELD_GEOJSON: 'Soil Water Content Field Geojson',
  SOIL_EROSION_FIELD_GEOJSON: 'Soil Erosion Field Geojson',
  LEAF_PHENOLOGY_FIELD_GEOJSON: 'Leaf Phenology Field Geojson',
  ELEVATION_FIELD_GEOJSON: 'Elevation Field Geojson',
  SLOPE_FIELD_GEOJSON: 'Slope Field Geojson',
  CUSTOM_FIELD_GEOJSON: 'Custom Field Geojson',
  SOIL_MOISTURE_CONTENT_GEOJSON: 'Soil Moisture Content Geojson',
  FISSURE_GEOJSON: 'Fissure Geojson',
  WATERSHED_BASIN_GEOJSON: 'Watershed Basin Geojson',
  VEGETATION_ENCROACHMENT_GEOJSON: 'Vegetation Encroachment Geojson',
  CONTOUR: 'Contour',
  TREE_TILT_FALLEN: 'Tree Tilt/Fallen',
};

export const mapFileToApi = {
  CHLOROPHYLL_FILE: 'chlorophyll-analysis',
  CHLOROPHYLL_PADDY_GEOJSON: 'chlorophyll-paddy-analysis',
  CIRCUMFERENCE_DATA_FILE: 'circumference-analysis',
  TREE_HEIGHT_FILE: 'tree-height-analysis',
  CO2_SEQUESTRATION_FILE: 'co2-sequestration-analysis',
  CROP_NONCROP_GEOJSON: 'crop-cover',
  CROWN_AREA_FILE: 'crown-area-analysis',
  GAP_FILE: 'gap-analysis',
  INTERVALS_FILE: 'interval-limit-analysis',
  LINE_STRING_FILE: 'land-use-analysis',
  POLYGON_FILE: 'land-use-analysis',
  POINT_FILE: 'land-use-analysis',
  PLANTATION_BOUNDARY_GEOJSON: 'crop-cover',
  PLANT_HEALTH_GEOJSON: 'plant-health-analysis',
  STAND_COUNT_FILE: 'stand-count-analysis',
  STAND_COUNT_CROP_BOUNDARY: 'stand-count-analysis',
  STRESS_FILE: 'stress-analysis',
  TILLER_DENSITY_GEOJSON: 'tiller-density-analysis',
  VACANT_AREA_GEOJSON: 'vacant-area-analysis',
  VIGOR_FILE: 'vigor-analysis',
  VIGOR_PADDY_GEOJSON: 'vigor-paddy-analysis',
  WATER_UPTAKE_FILE: 'water-uptake-analysis',
  WEED_INVASION_GEOJSON: 'weed-invasion-analysis',
  PLAN_HEALTH_OILPALM_FILE: 'plant-health-oilpalm-analysis',
  STRESS_FIELD_GEOJSON: 'stress-field-analysis',
  WATER_UPTAKE_FIELD_GEOJSON: 'water-uptake-field-analysis',
  SOIL_WATER_CONTENT_FIELD_GEOJSON: 'soil-water-content-field-analysis',
  SOIL_EROSION_FIELD_GEOJSON: 'soil-erosion',
  SLOPE_FIELD_GEOJSON: 'slope-field-analysis',
  CUSTOM_FIELD_GEOJSON: 'custom-field-analysis',
  LEAF_PHENOLOGY_FIELD_GEOJSON: 'leaf-phenology-field-analysis',
  ELEVATION_FIELD_GEOJSON: 'elevation-field-analysis',
  SOIL_MOISTURE_CONTENT_GEOJSON: 'soil-moisture-content',
  FISSURE_GEOJSON: 'fissure',
  WATERSHED_BASIN_GEOJSON: 'watershed-basin',
  VEGETATION_ENCROACHMENT_GEOJSON: 'vegetation-encroachment-analysis',
  CONTOUR: 'contour',
};

export const dataTypeRaster = [
  { value: 0, label: TypeRaster.RGB },
  { value: 1, label: TypeRaster.RGB_MULTI_SPECTRAL },
];

export enum LayerTypeEnum {
  RGB_ORTHOIMAGE = 'RGB_ORTHOIMAGE',
  DIGITAL_SURFACE_MODEL = 'DIGITAL_SURFACE_MODEL',
  DIGITAL_TERRAIN_MODEL = 'DIGITAL_TERRAIN_MODEL',
  HILL_SHADE = 'HILL_SHADE',

  VIGOR_FIELD = 'VIGOR_FIELD',
  VIGOR_TREE = 'VIGOR_TREE',
  STRESS_FIELD = 'STRESS_FIELD',
  STRESS_TREE = 'STRESS_TREE',
  CHLOROPHYLL_FIELD = 'CHLOROPHYLL_FIELD',
  CHLOROPHYLL_TREE = 'CHLOROPHYLL_TREE',
  WATER_UPTAKE_FIELD = 'WATER_UPTAKE_FIELD',
  WATER_UPTAKE_TREE = 'WATER_UPTAKE_TREE',
  PLANT_HEALTH_FIELD = 'PLANT_HEALTH_FIELD',
  PLANT_HEALTH_TREE = 'PLANT_HEALTH_TREE',
  SOIL_MOISTURE_CONTENT_FIELD = 'SOIL_MOISTURE_CONTENT_FIELD',
  SOIL_MOISTURE_CONTENT_POLYGON = 'SOIL_MOISTURE_CONTENT_POLYGON',
  VECTOR_CONTOUR = 'VECTOR_CONTOUR',
  STREAM_LINE_ORDER = 'STREAM_LINE_ORDER',
  LINES = 'LINES',
  TREE_TILT_FALLEN = 'TREE_TILT_FALLEN',
  SOIL_WATER_CONTENT_FIELD = 'SOIL_WATER_CONTENT_FIELD',
  SOIL_EROSION_FIELD = 'SOIL_EROSION_FIELD',
  SLOPE_FIELD = 'SLOPE_FIELD',
  CUSTOM_FIELD = 'CUSTOM_FIELD',
  LEAF_PHENOLOGY_FIELD = 'LEAF_PHENOLOGY_FIELD',
  ELEVATION_FIELD = 'ELEVATION_FIELD',
  FISSURE = 'FISSURE',
  VEGETATION_ENCROACHMENT = 'VEGETATION_ENCROACHMENT',
  CANOPY_HEIGHT_MODEL = 'CANOPY_HEIGHT_MODEL',
  COVER_20 = 'COVER_20',
  COVER_20_LEGEND = 'COVER_20_LEGEND',
  ABOVEGROUND_CARBON_DENSITY = 'ABOVEGROUND_CARBON_DENSITY',
  ABOVEGROUND_CARBON_DENSITY_LEGEND = 'ABOVEGROUND_CARBON_DENSITY_LEGEND',
  CO2_SEQUESTRATION = 'CO2_SEQUESTRATION',
  CO2_SEQUESTRATION_LEGEND = 'CO2_SEQUESTRATION_LEGEND',
}

export const DATA_RASTERS = {
  [TypeRaster.RGB]: [
    LayerTypeEnum.RGB_ORTHOIMAGE,
    LayerTypeEnum.DIGITAL_SURFACE_MODEL,
    LayerTypeEnum.DIGITAL_TERRAIN_MODEL,
    LayerTypeEnum.HILL_SHADE,
  ],
  [TypeRaster.RGB_MULTI_SPECTRAL]: [
    LayerTypeEnum.RGB_ORTHOIMAGE,
    LayerTypeEnum.DIGITAL_SURFACE_MODEL,
    LayerTypeEnum.DIGITAL_TERRAIN_MODEL,
    LayerTypeEnum.HILL_SHADE,
  ],
};

export const LABEL_DATA_RASTERS = {
  [LayerTypeEnum.RGB_ORTHOIMAGE]: 'Orthoimage',
  [LayerTypeEnum.VIGOR_FIELD]: 'Vigor Field',
  [LayerTypeEnum.VIGOR_TREE]: 'Vigor Tree',
  [LayerTypeEnum.STRESS_FIELD]: 'Stress Field',
  [LayerTypeEnum.STRESS_TREE]: 'Stress Tree',
  [LayerTypeEnum.CHLOROPHYLL_FIELD]: 'Chlorophyll Field',
  [LayerTypeEnum.CHLOROPHYLL_TREE]: 'Chlorophyll Tree',
  [LayerTypeEnum.WATER_UPTAKE_FIELD]: 'Water Uptake field',
  [LayerTypeEnum.WATER_UPTAKE_TREE]: 'Water Uptake Tree',
  [LayerTypeEnum.PLANT_HEALTH_FIELD]: 'Plant Health Field',
  [LayerTypeEnum.PLANT_HEALTH_TREE]: 'Plant Health Tree',
  [LayerTypeEnum.SOIL_WATER_CONTENT_FIELD]: 'Soil Water Content Field',
  [LayerTypeEnum.SOIL_EROSION_FIELD]: 'Soil Erosion Field',
  [LayerTypeEnum.LEAF_PHENOLOGY_FIELD]: 'Leaf Phenology Field',
  [LayerTypeEnum.ELEVATION_FIELD]: 'Elevation Field',
  [LayerTypeEnum.SLOPE_FIELD]: 'Slope Field',
  [LayerTypeEnum.CUSTOM_FIELD]: 'Custom Field',
  [LayerTypeEnum.SOIL_MOISTURE_CONTENT_FIELD]: 'Soil Moisture Content Field',
  [LayerTypeEnum.SOIL_MOISTURE_CONTENT_POLYGON]: 'Soil Moisture Content Polygon',
  [LayerTypeEnum.FISSURE]: 'Fissure',
  [LayerTypeEnum.VECTOR_CONTOUR]: 'Contour',
  [LayerTypeEnum.STREAM_LINE_ORDER]: 'Streamline Order',
  [LayerTypeEnum.LINES]: 'Line String File',
  [LayerTypeEnum.TREE_TILT_FALLEN]: 'Tree Tilt/Fallen',
  [LayerTypeEnum.DIGITAL_SURFACE_MODEL]: 'Digital Surface Model',
  [LayerTypeEnum.DIGITAL_TERRAIN_MODEL]: 'Digital Terrain Model',
  [LayerTypeEnum.HILL_SHADE]: 'Hill Shade',
  [LayerTypeEnum.VEGETATION_ENCROACHMENT]: 'Vegetation Encroachment',
  [LayerTypeEnum.CANOPY_HEIGHT_MODEL]: 'Canopy Height Model',
  [LayerTypeEnum.COVER_20]: 'Cover20',
  [LayerTypeEnum.COVER_20_LEGEND]: 'Cover20 Legend',
  [LayerTypeEnum.ABOVEGROUND_CARBON_DENSITY]: 'Aboveground Carbon Density',
  [LayerTypeEnum.ABOVEGROUND_CARBON_DENSITY_LEGEND]: 'Aboveground Carbon Density Legend',
  [LayerTypeEnum.CO2_SEQUESTRATION]: 'CO2 Sequestration',
  [LayerTypeEnum.CO2_SEQUESTRATION_LEGEND]: 'CO2 Sequestration Legend',
};

export const DEFAULT_SURFACE_SETTINGS = {
  COLOR: '#fff',
  OPACITY: 1,
  IS_SAME_SIDE_SURFACE_OPACITY: false,
  OPACITY_2D: 1,
};

export const uiSettingDescriptionMapping: Record<string, string> = {
  [SettingUIName.STROKE_WIDTH]: 'Width',
  [SettingUIName.CONTOUR_3D_HEIGHT]: 'Height',
  [SettingUIName.CYLINDAR_3D_RADIUS]: 'Radius',
};

export const uiIconSettingFavorite: Record<string, string> = {
  [SettingUIIconFavorite.FAVORITE_ICON]: 'Icon',
  [SettingUIIconFavorite.FAVORITE_COLOR]: 'Color',
};

export const uiViewAnalysisProperties: Record<string, string> = {
  [SettingUIAnalysis.SHOW_HIDE_SLOPE_ANALYSIS]: 'Show/Hide Slope Analysis',
};

export const uiSizePointConfig: Record<string, string> = {
  [SettingUISizePointAnalysis.SIZE_CONFIG]: 'Fixed Size',
};

export enum MousePositionEnum {
  TOP_LEFT = 'top-left',
  TOP_CENTER = 'top',
  TOP_RIGHT = 'top-right',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_CENTER = 'bottom',
  BOTTOM_RIGHT = 'bottom-right',
}

export const analyticsDisplayedList = [
  TYPE_ANALYTICS_MAP_VIEW.CROWN_AREA,
  TYPE_ANALYTICS_MAP_VIEW.GAP,
  TYPE_ANALYTICS_MAP_VIEW.CIRCUMFERENCE_ANALYSIS,
  TYPE_ANALYTICS_MAP_VIEW.TREE_HEIGHT,
  TYPE_ANALYTICS_MAP_VIEW.STRESS_TREE,
  TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_TREE,
  TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_TREE,
  TYPE_ANALYTICS_MAP_VIEW.VIGOR_TREE,
  TYPE_ANALYTICS_MAP_VIEW.CO2_SEQUESTRATION,
];

export enum ComparedIndex {
  FIRST = 1,
  SECOND = 2,
}

export enum MapStateKey {
  MAP_VIEW = 'MAP_VIEW',
  MAP_POPUP = 'MAP_POPUP',
}

export const FILE_TYPE_GEOJSON_INTERVAL_FILE = [
  FILE_TYPE.CHLOROPHYLL_FILE,
  FILE_TYPE.STRESS_FILE,
  FILE_TYPE.VIGOR_FILE,
  FILE_TYPE.WATER_UPTAKE_FILE,
  FILE_TYPE.PLAN_HEALTH_OILPALM_FILE,
  FILE_TYPE.CIRCUMFERENCE_DATA_FILE,
  FILE_TYPE.CROWN_AREA_FILE,
];

export const LIST_FILE_RASTER = [
  LayerTypeEnum.RGB_ORTHOIMAGE,
  LayerTypeEnum.DIGITAL_SURFACE_MODEL,
  LayerTypeEnum.DIGITAL_TERRAIN_MODEL,
  LayerTypeEnum.HILL_SHADE,
  LayerTypeEnum.VIGOR_FIELD,
  LayerTypeEnum.VIGOR_TREE,
  LayerTypeEnum.STRESS_FIELD,
  LayerTypeEnum.STRESS_TREE,
  LayerTypeEnum.CHLOROPHYLL_FIELD,
  LayerTypeEnum.CHLOROPHYLL_TREE,
  LayerTypeEnum.WATER_UPTAKE_FIELD,
  LayerTypeEnum.WATER_UPTAKE_TREE,
  LayerTypeEnum.PLANT_HEALTH_FIELD,
  LayerTypeEnum.PLANT_HEALTH_TREE,
  LayerTypeEnum.SOIL_WATER_CONTENT_FIELD,
  LayerTypeEnum.SOIL_EROSION_FIELD,
  LayerTypeEnum.LEAF_PHENOLOGY_FIELD,
  LayerTypeEnum.ELEVATION_FIELD,
  LayerTypeEnum.SLOPE_FIELD,
  LayerTypeEnum.CUSTOM_FIELD,
  LayerTypeEnum.VECTOR_CONTOUR,
  LayerTypeEnum.STREAM_LINE_ORDER,
  LayerTypeEnum.LINES,
  LayerTypeEnum.TREE_TILT_FALLEN,
  LayerTypeEnum.VEGETATION_ENCROACHMENT,
  LayerTypeEnum.CANOPY_HEIGHT_MODEL,
  LayerTypeEnum.COVER_20,
  LayerTypeEnum.COVER_20_LEGEND,
  LayerTypeEnum.ABOVEGROUND_CARBON_DENSITY,
  LayerTypeEnum.ABOVEGROUND_CARBON_DENSITY_LEGEND,
  LayerTypeEnum.CO2_SEQUESTRATION,
  LayerTypeEnum.CO2_SEQUESTRATION_LEGEND,
];

export const listRasterLayers = [
  LayerTypeEnum.RGB_ORTHOIMAGE,
  LayerTypeEnum.DIGITAL_SURFACE_MODEL,
  LayerTypeEnum.DIGITAL_TERRAIN_MODEL,
  LayerTypeEnum.HILL_SHADE,
];

export const ToastMessage = {
  DSM_DTM_LONG_PROGRESS:
    'This process may take some time to complete. Please do not close the modal until it is finished.',
  LACK_ORTHOIMAGE_FILE: 'Orthoimage (RGB band) is required to upload DSM and DTM.',
};

export enum MapEnum {
  MAP_VIEW = 'MAP_VIEW',
  CROP_INTELLIGENT = 'CROP_INTELLIGENT',
}

export type TSortOrder = 'ASC' | 'DESC' | null;
export interface ITableHeaders<T> {
  key: T;
  label: string;
  sortOrder: TSortOrder;
}
