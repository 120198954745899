import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import {
  Avatar,
  Box,
  Button,
  debounce,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { ICreateUIFormData } from 'common/defines/clients';
import ErrorMessage from 'components/Common/ErrorMessage';
import ShowSingleUI from 'components/PermissionRole/ShowSigleUI';
import { permissionsData, QUERY_KEY } from 'constants/constants';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import {
  createUiDisplay,
  getClientListByPagination,
  getUIDisplay,
  getUserListByPagination,
  updateUIDisplay,
} from 'services/clients/apiClient.services';

const UIConfigurationPage = () => {
  const [clientList, setClientList] = useState<any[]>([]);
  const [openClientSelect, setOpenClientSelect] = useState(false);
  const [openUserSelect, setOpenUserSelect] = useState(false);
  const [isUpdate, setIsUpdate] = useState('');
  const [pageClient, setPageClient] = useState(1);
  const rowsPerPageClient = 5;
  const [searchClient, setSearchClient] = useState('');
  const [pageUser, setPageUser] = useState(1);
  const rowsPerPageUser = 5;
  const [searchUser, setSearchUser] = useState('');
  const [checkSwitchDasboard, setCheckSwitchDasboard] = useState(true);
  const [checkSwitchMapview, setCheckSwitchMapview] = useState(true);
  const [checkSwitchUtilitySetting, setCheckSwitchUtilitySetting] = useState<boolean>(true);
  const [flagTab, setFlagTab] = useState<string[]>([]);
  const [isShowDashboard, setIsShowDashboard] = useState(false);
  const [isShowMapview, setIsShowMapview] = useState(false);
  const [isShowUtilitySetting, setIsShowUtilitySetting] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      clientId: '',
      userId: '',
      flagTab: flagTab,
    },
  });

  const theme = useTheme();

  const { data: clientPaginationValue } = useQuery(
    [QUERY_KEY.CLIENT_DETAILS, pageClient, rowsPerPageClient, searchClient],
    () => getClientListByPagination(pageClient, rowsPerPageClient, searchClient),
    {
      keepPreviousData: false,
      enabled: openClientSelect,
      onSuccess: (data) => {
        const clientNameData = data.data.data.map((item: any) => {
          return {
            value: item._id,
            name: item.name,
            avatar: item.avatar,
            email: item.email,
          };
        });
        setClientList(clientNameData);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  const [childenDasboard, setChildenDasboard] = useState<any>([]);
  useEffect(() => {
    setChildenDasboard(permissionsData.filter((item: any) => item.key.includes('DASHBOARD')));
  }, [permissionsData]);

  const [childenMapview, setChildenMapview] = useState<any>([]);
  useEffect(() => {
    setChildenMapview(permissionsData.filter((item: any) => item.key.includes('MAPVIEW')));
  }, [permissionsData]);

  const [childrenUtilitySetting, setChildrenUtilitySetting] = useState<any[]>([]);
  useEffect(() => {
    setChildrenUtilitySetting(permissionsData.filter((item: any) => item.key.includes('UTILITY-SETTINGS')));
  }, []);

  const dataClientValue = useMemo(() => {
    return clientPaginationValue?.data?.metadata;
  }, [clientPaginationValue]);

  const { data: userListOfClient } = useQuery(
    [QUERY_KEY.USER_DETAILS, pageUser, rowsPerPageUser, searchUser, getValues().clientId],
    () => getUserListByPagination(pageUser, rowsPerPageUser, searchUser, getValues().clientId || null),
    {
      keepPreviousData: false,
      enabled: getValues().clientId !== '' && openUserSelect,
    }
  );

  const dataUserValue = useMemo(() => {
    return userListOfClient?.data[0]?.metadata[0];
  }, [userListOfClient]);

  // Call API get userInfoByClient
  const { data: userInfoByClient, isLoading } = useQuery(
    [QUERY_KEY.USER_INFO_BY_CLIENT, getValues().clientId, getValues().userId],
    () => getUIDisplay(getValues().clientId, getValues().userId),
    {
      enabled: getValues().clientId !== '' && getValues().userId !== '',
      onSuccess: (data) => {
        if (!data.data._id) {
          setIsUpdate('');
        } else {
          setIsUpdate(data.data._id);
        }
      },
    }
  );

  const debounceSearchClient = debounce((value: string) => {
    setSearchClient(value);
  }, 500);

  const debounceSearchUser = debounce((value: string) => {
    setSearchUser(value);
  }, 500);

  const flagTabUser = useMemo(() => {
    if (isLoading) {
      setFlagTab([]);
      return [];
    } else if (userInfoByClient?.data) {
      setFlagTab(userInfoByClient?.data?.flagTab);
      return userInfoByClient?.data?.flagTab;
    } else {
      setFlagTab([]);
      return [];
    }
  }, [isLoading, userInfoByClient]);

  const userValueList = useMemo(() => {
    if (userListOfClient) {
      return userListOfClient.data[0].docs.map((item: any) => {
        return {
          value: item._id,
          name: item.name,
          avatar: item.avatar,
          email: item.email,
        };
      });
    }
    return [];
  }, [userListOfClient]);

  const handleSelectClient = useCallback(
    (event: any) => {
      setValue('clientId', event.target.value);
      setValue('userId', '');
    },
    [setValue]
  );

  const handleSelectUser = useCallback(
    (event: any) => {
      setValue('userId', event.target.value);
    },
    [setValue]
  );

  useEffect(() => {
    setValue('flagTab', flagTab);
  }, [flagTab, setValue]);

  // Call API Update UI Display
  const useUpdateUIDisplay = () => {
    return useMutation((param: any) => updateUIDisplay(isUpdate, param), {
      onSuccess: () => {
        toast.success('Update success');
      },
      onError: (err: any) => {
        toast.error(err.data.message, { toastId: 1 });
      },
    });
  };

  const mutationUpdateUIDisplay = useUpdateUIDisplay();

  const useCreateUIFormData = () => {
    return useMutation((param: any) => createUiDisplay(param), {
      onSuccess: () => {
        toast.success('Create UI Success', { toastId: 1 });
      },
      onError: (err: any) => {
        toast.error(err.data.message, { toastId: 1 });
      },
    });
  };

  const mutationCreateUIForm = useCreateUIFormData();

  const handleSubmitForm: SubmitHandler<ICreateUIFormData> = (body) => {
    if (isUpdate === '') {
      // Create UI
      mutationCreateUIForm.mutate(body);
    } else {
      // Update UI
      mutationUpdateUIDisplay.mutate(body);
    }
  };
  const newFlagTab = useMemo(() => {
    if (!flagTab) {
      return [];
    }
    return flagTab;
  }, [flagTab]);
  const checkFlagTabDasboard = useCallback(
    (isChecked: boolean) => {
      if (!isChecked) {
        setFlagTab(newFlagTab?.filter((item: string) => !item.includes('DASHBOARD')));
      }
      setIsShowDashboard(isChecked);

      setCheckSwitchDasboard(!checkSwitchDasboard);
    },
    [checkSwitchDasboard, setFlagTab, newFlagTab]
  );
  const checkFlagTabMapview = useCallback(
    (isChecked: boolean) => {
      if (!isChecked) {
        setFlagTab(newFlagTab?.filter((item: string) => !item.includes('MAPVIEW')));
      }
      setIsShowMapview(isChecked);
      setCheckSwitchMapview(isChecked);
    },
    [setFlagTab, newFlagTab]
  );

  const checkFlagTabUtilitySetting = useCallback(
    (isChecked: boolean) => {
      if (!isChecked) {
        setFlagTab(newFlagTab?.filter((item: string) => !item.includes('UTILITY-SETTINGS')));
      }
      setIsShowUtilitySetting(isChecked);
      setCheckSwitchUtilitySetting(isChecked);
    },
    [setFlagTab, newFlagTab]
  );

  const handleKeyDown = (event: React.KeyboardEvent) => {
    event.stopPropagation();
  };

  return (
    <Box
      component="form"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '12px 24px',
      }}
      onSubmit={handleSubmit(handleSubmitForm)}>
      {/* Select Client & Select User */}
      <Box
        sx={{
          padding: '12px 0',
          display: 'flex',
          width: '60%',
        }}>
        <Box
          sx={{
            flex: 1,
            padding: '0px 12px',
          }}>
          <Controller
            name="clientId"
            control={control}
            render={({ field }) => (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                  justifyContent: 'center',
                }}>
                <InputLabel id="select-client-label">Select Client</InputLabel>
                <Select
                  sx={{
                    height: '46px',
                    padding: '0px 14px',
                    width: '100%',
                  }}
                  open={openClientSelect}
                  onOpen={() => {
                    setOpenClientSelect(true);
                    setSearchClient('');
                  }}
                  onClose={() => {
                    setOpenClientSelect(false);
                    setSearchClient('');
                  }}
                  fullWidth
                  {...field}
                  onChange={(event) => handleSelectClient(event)}>
                  <TextField
                    type="text"
                    placeholder="Search Clients..."
                    sx={{
                      m: '12px',
                      width: 'calc(100% - 24px)',
                      height: '40px',
                      '.MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '.MuiOutlinedInput-root': {
                        height: '40px',
                      },
                      border: `1px solid ${theme.palette.divider}`,
                      borderRadius: '5px',
                    }}
                    onChange={(e) => {
                      debounceSearchClient(e.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                    InputProps={{
                      size: 'small',
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon sx={{ height: '24px', width: '24px' }} />
                        </InputAdornment>
                      ),
                      inputProps: {
                        style: {
                          fontFamily: 'Barlow',
                          fontStyle: 'normal',
                          fontWeight: 'normal',
                          fontSize: '14px',
                          lineHeight: '17px',
                        },
                      },
                    }}
                  />
                  <Box sx={{ border: `1px solid ${theme.palette.divider}` }} />
                  {clientList.length > 0 ? (
                    clientList.map((data: any) => (
                      <MenuItem key={data.value} value={data.value}>
                        <Box
                          sx={{
                            display: 'flex',
                          }}>
                          <Avatar
                            sx={{
                              width: '36px',
                              height: '36px',
                              marginRight: '10px',
                            }}
                            src={data.avatar}
                          />
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}>
                            <Typography>{data.name}</Typography>
                            <Typography>{data.email}</Typography>
                          </Box>
                        </Box>
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: theme.palette.mode === 'dark' ? '#EDF1F1' : '#9e9e9e',
                        minHeight: '54px !important',
                      }}>
                      No Client Found
                    </MenuItem>
                  )}
                  <Box sx={{ border: `1px solid ${theme.palette.divider}` }} />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      py: '12px',
                    }}>
                    <Pagination
                      sx={{
                        '& .MuiPagination-ul': {
                          '& .Mui-selected': {
                            backgroundColor: (theme) => theme.palette.primary.main,
                            color: 'white',
                          },
                          '& .Mui-selected:hover': {
                            backgroundColor: '#23BE6AB0',
                          },
                          '& .MuiPaginationItem-icon': {
                            color: (theme) => theme.palette.primary.main,
                          },
                        },
                      }}
                      count={dataClientValue?.pageCount}
                      defaultPage={1}
                      page={pageClient}
                      onChange={(_, value) => {
                        setPageClient(value);
                        setValue('clientId', '');
                      }}
                    />
                  </Box>
                </Select>
                {errors.clientId && <ErrorMessage>{errors.clientId.message}</ErrorMessage>}
              </Box>
            )}
          />
        </Box>
        {/* Select UserId */}
        <Box
          sx={{
            flex: 1,
            padding: '0px 12px',
          }}>
          <Controller
            name="userId"
            control={control}
            render={({ field }) => (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                  justifyContent: 'center',
                }}>
                <InputLabel id="select-userId-label">Select User</InputLabel>
                <Select
                  sx={{
                    height: '46px',
                    padding: '0px 14px',
                    width: '100%',
                  }}
                  open={openUserSelect}
                  onOpen={() => {
                    setOpenUserSelect(true);
                    setSearchUser('');
                  }}
                  onClose={() => {
                    setOpenUserSelect(false);
                    setSearchUser('');
                  }}
                  fullWidth
                  {...field}
                  onChange={(event) => handleSelectUser(event)}>
                  <TextField
                    type="text"
                    placeholder="Search User..."
                    sx={{
                      m: '12px',
                      width: 'calc(100% - 24px)',
                      height: '40px',
                      '.MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '.MuiOutlinedInput-root': {
                        height: '40px',
                      },
                      border: `1px solid ${theme.palette.divider}`,
                      borderRadius: '5px',
                    }}
                    onChange={(e) => {
                      debounceSearchUser(e.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                    InputProps={{
                      size: 'small',
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon sx={{ height: '24px', width: '24px' }} />
                        </InputAdornment>
                      ),
                      inputProps: {
                        style: {
                          fontFamily: 'Barlow',
                          fontStyle: 'normal',
                          fontWeight: 'normal',
                          fontSize: '14px',
                          lineHeight: '17px',
                        },
                      },
                    }}
                  />
                  <Box sx={{ border: `1px solid ${theme.palette.divider}` }} />
                  {userValueList.length > 0 && getValues().clientId !== '' ? (
                    userValueList.map((data: any) => (
                      <MenuItem key={data.value} value={data.value}>
                        <Box
                          sx={{
                            display: 'flex',
                          }}>
                          <Avatar
                            sx={{
                              width: '36px',
                              height: '36px',
                              marginRight: '10px',
                            }}
                            src={data.avatar}
                          />
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}>
                            <Typography>{data.name}</Typography>
                            <Typography>{data.email}</Typography>
                          </Box>
                        </Box>
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: theme.palette.mode === 'dark' ? '#EDF1F1' : '#9e9e9e',
                        minHeight: '54px !important',
                      }}>
                      No user found or Client is not selected yet
                    </MenuItem>
                  )}
                  <Box sx={{ border: `1px solid ${theme.palette.divider}` }} />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      py: '12px',
                    }}>
                    <Pagination
                      sx={{
                        '& .MuiPagination-ul': {
                          '& .Mui-selected': {
                            backgroundColor: (theme) => theme.palette.primary.main,
                            color: 'white',
                          },
                          '& .Mui-selected:hover': {
                            backgroundColor: '#23BE6AB0',
                          },
                          '& .MuiPaginationItem-icon': {
                            color: (theme) => theme.palette.primary.main,
                          },
                        },
                      }}
                      count={Math.ceil(dataUserValue?.total / dataUserValue?.limit)}
                      defaultPage={1}
                      page={pageUser}
                      onChange={(_, value) => {
                        setPageUser(value);
                        setValue('userId', '');
                      }}
                    />
                  </Box>
                </Select>
                {errors.userId && <ErrorMessage>{errors.userId.message}</ErrorMessage>}
              </Box>
            )}
          />
        </Box>
      </Box>
      {getValues().clientId !== '' && getValues().userId !== '' ? (
        <Box
          sx={{
            padding: '0 0 12px',
          }}>
          {/* UI show/hide */}
          <InputLabel sx={{ display: 'flex', marginLeft: 2, marginTop: 1 }}>Show/Hide UI</InputLabel>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box sx={{ width: '33%' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}>
                <Box
                  onClick={() => {
                    if (checkSwitchDasboard) {
                      setIsShowDashboard(!isShowDashboard);
                    } else {
                      setIsShowDashboard(false);
                    }
                  }}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    padding: '10px',
                    borderRadius: '10px 10px 0 0',
                    backgroundColor: theme.palette.mode === 'dark' ? '#1E1E1E' : '#F2F2F2',
                    border: `1px solid ${theme.palette.divider}`,
                  }}>
                  <Typography
                    sx={{
                      marginRight: '1rem',
                    }}>
                    <Box sx={{ display: 'flex' }}>
                      {isShowDashboard ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                      <Box sx={{ marginLeft: '2px' }}>DASHBOARD</Box>
                    </Box>
                  </Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={(_: any, _isChecked: boolean) => checkFlagTabDasboard(_isChecked)}
                        checked={checkSwitchDasboard}
                      />
                    }
                    label=""
                  />
                </Box>
              </Box>

              <Box>
                <Controller
                  name="flagTab"
                  control={control}
                  render={({ field }) => (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                      }}>
                      {childenDasboard.map((data: any, index: React.Key | null | undefined) => {
                        return (
                          <Stack
                            spacing={1}
                            key={index}
                            sx={{
                              width: '100%',
                            }}>
                            <ShowSingleUI
                              isShowDashboard={isShowDashboard}
                              data={data}
                              flagTab={flagTab}
                              setFlagTab={setFlagTab}
                              flagTabUser={flagTabUser}
                            />
                          </Stack>
                        );
                      })}
                    </Box>
                  )}
                />
              </Box>
            </Box>
            <Box sx={{ width: '33%' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}>
                <Box
                  onClick={() => {
                    if (checkSwitchMapview) {
                      setIsShowMapview(!isShowMapview);
                    } else {
                      setIsShowMapview(false);
                    }
                  }}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    padding: '10px',
                    borderRadius: '10px 10px 0 0',
                    backgroundColor: theme.palette.mode === 'dark' ? '#1E1E1E' : '#F2F2F2',
                    border: `1px solid ${theme.palette.divider}`,
                  }}>
                  <Typography
                    sx={{
                      marginRight: '1rem',
                    }}>
                    <Box sx={{ display: 'flex' }}>
                      {isShowMapview ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                      <Box sx={{ marginLeft: '2px' }}>MAPVIEW</Box>
                    </Box>
                  </Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={(_: any, _isChecked: boolean) => checkFlagTabMapview(_isChecked)}
                        checked={checkSwitchMapview}
                      />
                    }
                    label=""
                  />
                </Box>
              </Box>
              <Box>
                <Controller
                  name="flagTab"
                  control={control}
                  render={({ field }) => (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                      }}>
                      {childenMapview.map((data: any, index: React.Key | null | undefined) => {
                        return (
                          <Stack
                            spacing={1}
                            key={index}
                            sx={{
                              width: '100%',
                            }}>
                            <ShowSingleUI
                              isShowMapview={isShowMapview}
                              data={data}
                              flagTab={flagTab}
                              setFlagTab={setFlagTab}
                              flagTabUser={flagTabUser}
                            />
                          </Stack>
                        );
                      })}
                    </Box>
                  )}
                />
              </Box>
            </Box>
            {/* ********************************** */}
            <Box sx={{ width: '33%' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}>
                <Box
                  onClick={() => {
                    if (checkSwitchUtilitySetting) {
                      setIsShowUtilitySetting(!isShowUtilitySetting);
                    } else {
                      setIsShowUtilitySetting(false);
                    }
                  }}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    padding: '10px',
                    borderRadius: '10px 10px 0 0',
                    backgroundColor: theme.palette.mode === 'dark' ? '#1E1E1E' : '#F2F2F2',
                    border: `1px solid ${theme.palette.divider}`,
                  }}>
                  <Typography
                    sx={{
                      marginRight: '1rem',
                    }}>
                    <Box sx={{ display: 'flex' }}>
                      {isShowUtilitySetting ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                      <Box sx={{ marginLeft: '2px' }}>UTILITY SETTINGS</Box>
                    </Box>
                  </Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={(_: any, _isChecked: boolean) => checkFlagTabUtilitySetting(_isChecked)}
                        checked={checkSwitchUtilitySetting}
                      />
                    }
                    label=""
                  />
                </Box>
              </Box>
              <Box>
                <Controller
                  name="flagTab"
                  control={control}
                  render={({ field }) => (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                      }}>
                      {childrenUtilitySetting.map((data: any, index: React.Key | null | undefined) => {
                        return (
                          <Stack
                            spacing={1}
                            key={index}
                            sx={{
                              width: '100%',
                            }}>
                            <ShowSingleUI
                              isShowUtilitySetting={isShowUtilitySetting}
                              data={data}
                              flagTab={flagTab}
                              setFlagTab={setFlagTab}
                              flagTabUser={flagTabUser}
                            />
                          </Stack>
                        );
                      })}
                    </Box>
                  )}
                />
              </Box>
            </Box>
            {/* ********************************** */}
          </Box>
          {/* Controller submit */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: 2,
            }}>
            {isUpdate === '' ? (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  height: '40px',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  lineHeight: '22px',
                  display: 'flex',
                  padding: '6px 64px',
                }}>
                Create
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  height: '40px',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  lineHeight: '22px',
                  display: 'flex',
                  padding: '6px 64px',
                }}>
                Update
              </Button>
            )}
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            padding: '0 0 12px',
          }}>
          <InputLabel sx={{ display: 'flex', marginLeft: 2, marginTop: 1 }}>Show/Hide UI</InputLabel>
          <Typography
            sx={{
              color: '#9B9B9B',
              fontSize: '14px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              marginLeft: 2,
            }}>
            Please select Client and User to set Show/Hide UI form
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default UIConfigurationPage;
