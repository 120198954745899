import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Button, styled, TablePagination, Typography, useTheme } from '@mui/material';
import { ClientActionsDialog } from 'components/ClientsInfo/ClientActionsDialog';
import CalendarModal from 'components/MapView/RightBar/IssuesTab/components/modals/CalendarModal';
import { IDateRange, SortDateEnum } from 'components/MapView/RightBar/IssuesTab/interfaces';
import { QUERY_KEY } from 'constants/constants';
import { startOfYear } from 'date-fns';
import useQueryListTaskOfField from 'hooks/workspace/useQueryListTaskOfField';
import useQueryWorkspaceInfo from 'hooks/workspace/useQueryWorkspaceInfo';
import { CreateAndEditMode, IField, ITask } from 'interfaces/workspace';
import moment from 'moment';
import { ChangeEvent, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteField, deleteTasks, getFieldList } from 'services/workspaces';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeCreateAndEditFieldModal,
  changeIsResetMapData,
  changeNewestCreatedFieldId,
  changeSelectedFieldId,
  changeSelectedTaskId,
  tilingMapSelector,
} from 'store/slices/tilingMapSlice';
import CreateAndEditFieldModal from '../create-and-edit-field-modal';
import DeletedListTaskModal from './DeletedListTaskModal';
import FieldItem from './field-item';
import StatusCheckingModal from './StatusCheckingModal';

export const CustomButton = styled(Button)({
  width: '100%',
  maxWidth: '180px',
  height: '40px',
  textTransform: 'none',
  fontWeight: '400',
  borderRadius: '5px',
  cursor: 'pointer',
});

export interface ICheckbox extends ITask {
  checked: boolean;
}

const FieldList = () => {
  const theme = useTheme();
  const [isShowCheckStatusModal, setIsShowCheckStatusModal] = useState<boolean>(false);
  const [isShowConfirmDeleteModal, setIsShowConfirmDeleteModal] = useState<boolean>(false);
  const [isShowDeletedListTaskModal, setIsShowDeletedListTaskModal] = useState<boolean>(false);
  const [isSelectedCheckAll, setIsSelectedCheckAll] = useState<boolean>(false);
  const [listCheckBox, setListCheckbox] = useState<ICheckbox[]>([]);
  const [fieldList, setFieldList] = useState<IField[]>();
  const [isShowCalendar, setIsShowCalendar] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<IDateRange>({
    startDate: startOfYear(new Date()),
    endDate: new Date(),
    key: 'selection',
  });

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  console.log('🚀 ~ FieldList ~ totalCount:', totalCount);

  const dispatch = useAppDispatch();
  const {
    selectedFieldId,
    createAndEditFieldModal: { isShow: isShowCreateAndEditFieldModal },
  } = useAppSelector(tilingMapSelector);
  const { workspaceData } = useQueryWorkspaceInfo();
  const { workspaceId } = useParams();
  const queryClient = useQueryClient();
  const { theLastTask } = useQueryListTaskOfField();

  const CustomBox = styled(Box)({
    borderRadius: '8px',
    border: `1px solid ${theme.palette.divider}`,
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });

  useQuery(
    [QUERY_KEY.GET_LIST_FIELD, currentPage, rowsPerPage],
    () =>
      getFieldList({
        page: currentPage,
        paginationVersion: 2,
        perPage: rowsPerPage,
        sortDate: SortDateEnum.DESC,
        workspaceId: workspaceId!,
      }),
    {
      onSuccess(res) {
        const fieldListRes = res.data[0].docs as IField[];
        setFieldList(fieldListRes);
        setTotalCount(res.data[0].metadata[0].total);

        const newestCreatedFieldId = fieldListRes.length ? fieldListRes[0]._id : '';
        dispatch(changeNewestCreatedFieldId(newestCreatedFieldId));
      },
      enabled: !!workspaceData?._id,
    }
  );

  const deleteFieldMutation = useMutation(deleteField, {
    onSuccess() {
      setIsShowConfirmDeleteModal(false);
      setIsShowDeletedListTaskModal(false);
      dispatch(changeSelectedFieldId(''));
      dispatch(changeIsResetMapData(true));
      dispatch(changeNewestCreatedFieldId(''));
      dispatch(changeSelectedTaskId(''));
      queryClient.invalidateQueries([QUERY_KEY.GET_LIST_FIELD]);
      queryClient.invalidateQueries([QUERY_KEY.GET_TASK_LIST_OF_FIELD]);
      toast.success('Delete Field Successfully');
    },
  });

  const deleteTaskMutation = useMutation(deleteTasks, {
    onSuccess() {
      setIsShowConfirmDeleteModal(false);
      queryClient.invalidateQueries([QUERY_KEY.GET_LIST_FIELD]);
      queryClient.invalidateQueries([QUERY_KEY.GET_TASK_LIST_OF_FIELD]);
      toast.error('Delete Selected Data Successfully');
    },
  });

  const handleDeleteFieldData = () => {
    if (isSelectedCheckAll) {
      deleteFieldMutation.mutate(selectedFieldId);
    } else {
      const listDeletedId = listCheckBox.filter((item) => item.checked).map((item) => item._id);
      deleteTaskMutation.mutate({ idList: listDeletedId });
    }
  };

  const handleChangePage = (_: any, newPage: number) => {
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const formatDate = (date: Date | undefined) => {
    return moment(date).format('DD-MM-YYYY');
  };

  const handlerChangeDateSelect = (date: any) => {
    setSelectedDate(date[0]);
  };

  const handleClickAddField = () => {
    dispatch(changeSelectedFieldId(''));
    dispatch(changeCreateAndEditFieldModal({ isShow: true, mode: CreateAndEditMode.FIELD }));
  };

  return (
    <Box sx={{ mt: '20px', textAlign: 'center', px: '20px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', columnGap: '4px' }}>
        <CustomBox sx={{ width: '42px', cursor: 'pointer' }} onClick={handleClickAddField}>
          <AddIcon sx={{ color: theme.palette.primary.main }} />
        </CustomBox>
        <CustomBox sx={{ flexGrow: 1, position: 'relative' }}>
          <Box sx={{ display: 'inline', fontWeight: '500' }}>Filter: </Box>
          <Typography>&nbsp;{`${formatDate(selectedDate.startDate)} - ${formatDate(selectedDate.endDate)}`}</Typography>
          <MoreVertIcon
            onClick={() => setIsShowCalendar(true)}
            sx={{ cursor: 'pointer', position: 'relative', left: '6px', color: theme.palette.primary.main }}
          />
        </CustomBox>
      </Box>
      <Scrollbars style={{ width: '100%', height: '680px' }}>
        {fieldList &&
          fieldList.map((item, i) => (
            <FieldItem
              data={item}
              key={i}
              isSelected={item._id === selectedFieldId}
              isShowConfirmDeleteModal={isShowConfirmDeleteModal}
              theLastTask={theLastTask}
              setIsShowCheckStatusModal={setIsShowCheckStatusModal}
              setIsShowDeletedListTaskModal={setIsShowDeletedListTaskModal}
            />
          ))}
      </Scrollbars>
      <TablePagination
        component="div"
        count={totalCount}
        page={currentPage - 1}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          '& .MuiToolbar-root': { pl: 0 },
          '& .MuiTablePagination-selectLabel': {
            display: 'none',
          },
        }}
      />
      {isShowCreateAndEditFieldModal && (
        <CreateAndEditFieldModal
          isShow={true}
          handleClose={() => dispatch(changeCreateAndEditFieldModal({ isShow: false }))}
        />
      )}
      {isShowCheckStatusModal && (
        <StatusCheckingModal isShow={true} handleClose={() => setIsShowCheckStatusModal(false)} />
      )}
      {isShowCalendar && (
        <CalendarModal
          isShow={isShowCalendar}
          handleClose={() => setIsShowCalendar(false)}
          setSelectedDate={handlerChangeDateSelect}
          selectedDate={selectedDate}
        />
      )}
      {isShowConfirmDeleteModal && (
        <ClientActionsDialog
          title="Delete selected data"
          open={isShowConfirmDeleteModal}
          onClose={() => setIsShowConfirmDeleteModal(false)}
          description="Are you sure to about that?"
          saveLabel="Delete Data"
          onCancel={() => setIsShowConfirmDeleteModal(false)}
          onSave={handleDeleteFieldData}
          submit={false}
        />
      )}
      {isShowDeletedListTaskModal && (
        <DeletedListTaskModal
          isSelectedCheckAll={isSelectedCheckAll}
          setIsSelectedCheckAll={setIsSelectedCheckAll}
          listCheckBox={listCheckBox}
          setListCheckbox={setListCheckbox}
          handleClose={() => setIsShowDeletedListTaskModal(false)}
          setIsShowConfirmDeleteModal={setIsShowConfirmDeleteModal}
        />
      )}
    </Box>
  );
};

export default FieldList;
