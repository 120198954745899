import { Box, Stack, styled, Typography, useTheme } from '@mui/material';
import { IField, ITask } from 'interfaces/workspace';
import moment from 'moment';
import { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeSelectedFieldId, tilingMapSelector } from 'store/slices/tilingMapSlice';
import AddData from './AddData';
import FieldDelete from './FieldDelete';
import FieldDownload from './FieldDownload';
import FieldEditDetail from './FieldEditDetail';
import FieldSearchPosition from './FieldSearchPosition';

interface FieldItemProps {
  isSelected: boolean;
  data: IField;
  isShowConfirmDeleteModal: boolean;
  theLastTask: ITask | undefined;
  setIsShowCheckStatusModal: (arg: boolean) => void;
  setIsShowDeletedListTaskModal: (arg: boolean) => void;
}

const FieldItem: FC<FieldItemProps> = ({
  isSelected,
  data,
  isShowConfirmDeleteModal,
  theLastTask,
  setIsShowDeletedListTaskModal,
  setIsShowCheckStatusModal,
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { selectedFieldId } = useAppSelector(tilingMapSelector);

  const isIconActive = selectedFieldId === data._id;

  const dateList = data?.rasters;

  const handleSelectField = () => {
    dispatch(changeSelectedFieldId(data._id));
    if (!theLastTask?.status) {
      setIsShowCheckStatusModal(true);
    }
  };

  const CustomBox = styled(Box)({
    borderRadius: '8px',
    border: isSelected
      ? `1px solid ${theme.palette.primary.main}`
      : theme.palette.mode === 'dark'
        ? '1px solid #515151'
        : '1px solid #EFEFEF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  });

  return (
    <Box sx={{ mt: '16px', width: '100%' }} onClick={handleSelectField}>
      <CustomBox>
        <Stack sx={{ flexBasis: '40%', overflow: 'hidden', mx: '8px' }}>
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
              fontWeight: '600',
              fontSize: '16px',
              color: (theme) => (theme.palette.mode === 'dark' ? '#c2c4c5' : '#3C4043'),
            }}>
            {data?.name}
          </Typography>
          {dateList.slice(0, 3).map((item, i) => (
            <Box style={{ lineHeight: '12px' }} key={i}>
              <Typography
                sx={{
                  display: 'inline',
                  fontWeight: '500',
                  fontSize: '12px',
                  color: (theme) => (theme.palette.mode === 'dark' ? '#c2c4c5' : '#3C4043'),
                }}>
                Date {i + 1}:{' '}
              </Typography>
              <Typography
                sx={{
                  display: 'inline',
                  fontSize: '12px',
                  color: (theme) => (theme.palette.mode === 'dark' ? '#c2c4c5' : '#3C4043'),
                }}>
                {moment(item.date).format('DD-MM-YYYY')}
              </Typography>
            </Box>
          ))}
          {dateList.length > 3 && '...'}
        </Stack>
        <Box sx={{ flexBasis: '40%', display: 'flex', justifyContent: 'flex-end', mr: '16px' }}>
          {
            <>
              <FieldSearchPosition data={data} isIconActive={isIconActive} />
              <FieldDownload isIconActive={isIconActive} />
              <FieldEditDetail isIconActive={isIconActive} />
              <FieldDelete
                fieldId={data._id}
                isIconActive={isIconActive}
                isShowConfirmDeleteModal={isShowConfirmDeleteModal}
                setIsShowDeletedListTaskModal={setIsShowDeletedListTaskModal}
              />
              <AddData isIconActive={isIconActive} />
            </>
          }
        </Box>
      </CustomBox>
    </Box>
  );
};

export default FieldItem;
