import { Box } from '@mui/material';
import {
  Cartesian3,
  Cesium3DTileset as ICesium3DTileset,
  CesiumTerrainProvider,
  HeadingPitchRoll,
  Ion,
  Math,
  Transforms,
  Viewer as CesiumViewer,
} from 'cesium';
import configs from 'constants/config';
import { DEFAULT_VIEWPORT, QUERY_KEY } from 'constants/constants';
import useQueryFieldDetail from 'hooks/workspace/useQueryFieldDetail';
import useQueryListTaskOfField from 'hooks/workspace/useQueryListTaskOfField';
import { Map2dOptionEnum, Map3dOptionEnum } from 'interfaces/workspace';
import { FC, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { Cesium3DTileset, CesiumComponentRef, Viewer } from 'resium';
import { getMinMaxZoom } from 'services/workspaces';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeFlyingToState, tilingMapSelector } from 'store/slices/tilingMapSlice';

Ion.defaultAccessToken = process.env.REACT_APP_CESIUM || '';

const baseURl = configs.API_DOMAIN;

interface React3dMapProps { }

const React3dMap: FC<React3dMapProps> = () => {
  const map3dRef = useRef<CesiumComponentRef<CesiumViewer>>(null);
  const model3dRef = useRef<CesiumComponentRef<ICesium3DTileset>>(null);

  const dispatch = useAppDispatch();
  const [metaData, setMetaData] = useState<{ center: number[] }>();
  const [tileset3dUrl, setTileset3dUrl] = useState<string>('');
  const { selectedTask } = useQueryListTaskOfField();
  const { taskId, maxX, maxY, zoom, status } = selectedTask || {};
  const { fieldDetail } = useQueryFieldDetail();
  const { projectId } = fieldDetail || {};
  const {
    flyTo: { isDone: isDoneFly },
    selectedFieldId,
  } = useAppSelector(tilingMapSelector);

  const isEnableLogic = status && taskId && projectId;

  useQuery(
    [QUERY_KEY.GET_MIN_MAX_ZOOM, projectId, taskId],
    () => getMinMaxZoom(projectId!, taskId!, Map2dOptionEnum.dsm),
    {
      onSuccess(res) {
        setMetaData(res.data);
      },
      enabled: !!isEnableLogic,
    }
  );

  const getTerrainProvider = async () => {
    const terrainProvider = await CesiumTerrainProvider.fromIonAssetId(3957, {
      requestVertexNormals: true,
    });
    return terrainProvider;
  };

  // remove terrain cesium ion pick options
  useEffect(() => {
    if (map3dRef.current?.cesiumElement?.baseLayerPicker?.viewModel) {
      map3dRef.current.cesiumElement.baseLayerPicker.viewModel.terrainProviderViewModels = [];
    }
  }, [map3dRef?.current?.cesiumElement]);

  useEffect(() => {
    if (projectId && taskId) {
      setTileset3dUrl(`${baseURl}/field/3D/${projectId}/${taskId}/tileset.json`);
    }
  }, [projectId, selectedFieldId, taskId]);

  // fly to field location
  useEffect(() => {
    if (metaData) {
      const renderCenterCoordinates = () => {
        if (maxX && maxY && zoom && !isDoneFly) {
          return [Number(maxX), Number(maxY), zoom];
        } else {
          const zoomIndex = metaData?.center[-1] || 20;
          return (
            [...metaData?.center?.slice(0, 2)!, zoomIndex] || [
              DEFAULT_VIEWPORT.longitude,
              DEFAULT_VIEWPORT.latitude,
              DEFAULT_VIEWPORT.zoom,
            ]
          );
        }
      };
      const [modLong, modLat, modZoom] = renderCenterCoordinates();

      const position = Cartesian3.fromDegrees(modLong, modLat, modZoom);
      const heading = Math.toRadians(135);
      const pitch = 0;
      const roll = 0;
      const hpr = new HeadingPitchRoll(heading, pitch, roll);
      const orientation = Transforms.headingPitchRollQuaternion(position, hpr);
      map3dRef.current?.cesiumElement?.camera.flyTo({
        destination: Cartesian3.fromDegrees(modLong, modLat - 0.0015, modZoom * 10),
        orientation,
      });
      dispatch(changeFlyingToState({ isDone: true }));
    }
  }, [dispatch, isDoneFly, maxX, maxY, metaData, zoom]);

  const cesium3dTilesetList = [{ key: 1, show: true, url: `${tileset3dUrl}?typeView=${Map3dOptionEnum.MODEL_3D}` }];

  return (
    <Box
      sx={{
        '& .cesium-viewer-toolbar': {
          right: '100px',
        },
      }}>
      {projectId && taskId && (
        <Viewer
          requestRenderMode
          full
          ref={map3dRef}
          timeline={false}
          scene3DOnly
          terrainProvider={getTerrainProvider()}
          baseLayerPicker={true}
          homeButton={false}
          vrButton={false}
          navigationHelpButton={false}>
          {cesium3dTilesetList.map((item) => (
            <Cesium3DTileset
              key={item.key}
              ref={model3dRef}
              show={item.show}
              url={item.url}
              onReady={(tileset) => {
                map3dRef.current?.cesiumElement?.flyTo(tileset);
              }}
            />
          ))}
        </Viewer>
      )}
    </Box>
  );
};

export default React3dMap;
