import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { QUERY_KEY } from 'constants/constants';
import { FC, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { deleteAiDetection, generateAnalysis, getListWorkspaceAnalysis } from 'services/workspaces';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeIsClickedGenerateAnalytic,
  changeIsGeneratingAnalysis,
  tilingMapSelector,
} from 'store/slices/tilingMapSlice';
import CustomBox from './CustomBox';

interface AiDetectionProps { }

const AiDetection: FC<AiDetectionProps> = () => {
  const [isAnalysisListExist, setIsAnalysisListExist] = useState<boolean>(true);
  const queryClient = useQueryClient();

  const theme = useTheme();
  const dispatch = useAppDispatch();
  const {
    rightBar: { isClickedGenerateAnalytic },
    selectedTaskId,
  } = useAppSelector(tilingMapSelector);

  const { refetch: refetchAnalysisList } = useQuery(
    [QUERY_KEY.GET_WORKSPACE_ANALYSIS_LIST, selectedTaskId],
    () => getListWorkspaceAnalysis(selectedTaskId!),
    {
      onSuccess(res) {
        const resData = res.data;
        setIsAnalysisListExist(!!resData.length);
      },
      enabled: !!selectedTaskId,
    }
  );

  const generateAnalysisMutation = useMutation(generateAnalysis, {
    onMutate() {
      toast.success('Start generating analysis');
      dispatch(changeIsGeneratingAnalysis(true));
      dispatch(changeIsClickedGenerateAnalytic(true));
    },
    onSuccess() {
      dispatch(changeIsGeneratingAnalysis(false));
      queryClient.invalidateQueries([QUERY_KEY.GET_LIST_FIELD]);
    },
    onError() {
      toast.error('Generate analytic list failed');
      dispatch(changeIsGeneratingAnalysis(false));
      dispatch(changeIsClickedGenerateAnalytic(false));
    },
  });

  const deleteAiDetectionMutation = useMutation(deleteAiDetection, {
    onSuccess() {
      toast.success('Remove analytic list successful');
      refetchAnalysisList();
      dispatch(changeIsClickedGenerateAnalytic(false));
    },
    onError() {
      toast.error('Remove analytic list failed');
    },
  });

  const handleGenerateAnalysis = () => {
    generateAnalysisMutation.mutate(selectedTaskId);
  };

  return (
    <CustomBox>
      <Typography fontSize="16px">AI Detection</Typography>
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" gap="8px">
        <Button
          variant="outlined"
          size="small"
          onClick={handleGenerateAnalysis}
          disabled={isAnalysisListExist || isClickedGenerateAnalytic}>
          Start
        </Button>
        <DeleteForeverOutlinedIcon
          sx={{
            cursor: 'pointer',
            '&:hover': {
              color: theme.palette.error.main,
            },
          }}
          onClick={() => deleteAiDetectionMutation.mutate(selectedTaskId)}
        />
      </Box>
    </CustomBox>
  );
};

export default AiDetection;
