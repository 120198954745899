import { TableCell, TableCellProps } from '@mui/material';
import { styled } from '@mui/material/styles';

interface TableCellSummaryProps extends TableCellProps {
  isBold?: boolean;
}

const TableCellSummary = styled(TableCell)<TableCellSummaryProps>(({ theme, isBold = false }) => ({
  paddingRight: '16px',
  paddingLeft: '16px',
  color: theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B',
  textAlign: 'left',
  minWidth: '60px',
  fontWeight: isBold ? 700 : 400,
}));

export default TableCellSummary;
