import SwapVertIcon from '@mui/icons-material/SwapVert';
import {
  Box,
  SvgIcon,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { AscIcon, DescIcon } from 'assets/icons';
import { MeasurementUnit, SettingUnitName } from 'common/defines/clients';
import { ITableHeaders, TSortOrder } from 'common/defines/constants';
import { defaultClientUnitSetting } from 'common/dummy/dummyClients';
import { QUERY_KEY } from 'constants/constants';
import { find, isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getClientSettingsById } from 'services/clients/apiClient.services';
import { useAppSelector } from 'store/hooks';
import { clientSelector } from 'store/slices/clientSlice';
import { clientAreaUnitMeasurement } from '../IssuesTab/utils';
import TableCellHeaderSummary from './components/TableCellHeaderSummary';
import TableCellSummary from './components/TableCellSummary';

interface IRow {
  name: string;
  crop: number;
  nonCrop: number;
}

enum TableKeyEnum {
  TYPE = 'Level',
  CROP = 'Crop',
  NON_CROP = 'Non Crop',
}

export const CropCoverTable = () => {
  const { allLocationCropType } = useAppSelector(clientSelector);
  const { t } = useTranslation();
  const theme = useTheme();

  const initialTableHeaders = [
    { key: TableKeyEnum.TYPE, label: 'trans.level', sortOrder: null },
    { key: TableKeyEnum.CROP, label: 'mapView.crop', sortOrder: null },
    { key: TableKeyEnum.NON_CROP, label: 'mapView.non_crop', sortOrder: null },
  ];

  const [tableHeaders, setTableHeaders] = useState<ITableHeaders<TableKeyEnum>[]>(initialTableHeaders);
  const [sortInfo, setSortInfo] = useState<{ key: TableKeyEnum; sortOrder: TSortOrder }>({
    key: TableKeyEnum.TYPE,
    sortOrder: null,
  });

  const { clientId } = useParams();
  const { data: clientSetting } = useQuery(
    [QUERY_KEY.CLIENT_SETTINGS_BY_ID, clientId],
    () => getClientSettingsById(clientId || ''),
    {
      enabled: !!clientId,
    }
  );

  const areaSetting = useMemo(() => {
    return (
      clientSetting?.unitSetting?.find((data: any) => data.name === SettingUnitName.AREA) || defaultClientUnitSetting[0]
    );
  }, [clientSetting]);

  const areaUnit = useMemo(() => {
    const unitArea = areaSetting.unit;
    if (unitArea === MeasurementUnit.M2) {
      return 'm²';
    }
    if (unitArea === MeasurementUnit.KM2) {
      return 'km²';
    }
    return unitArea;
  }, [areaSetting]);

  const tableCropCoverage = useMemo(() => {
    const formatArray: any = allLocationCropType
      ?.filter((v: any) => !isEmpty(v.cropCoverAnalysisSummary))
      ?.map((item: any) => {
        const nonCropDataFilter = find(item.cropCoverAnalysisSummary, (i) => i.title === 'Noncrop');
        const cropDataFilter = find(item.cropCoverAnalysisSummary, (i) => i.title === 'Crop');

        return {
          name: item.name,
          nonCrop: nonCropDataFilter?.value || null,
          crop: cropDataFilter?.value || null,
          cropCoverAnalysisSummary: item?.cropCoverAnalysisSummary,
        };
      });

    const sortFunction = (first: IRow, second: IRow) => {
      const { key, sortOrder } = sortInfo;
      if (key === TableKeyEnum.TYPE) {
        return sortOrder === 'ASC' ? first.name?.localeCompare(second.name) : second.name?.localeCompare(first.name);
      } else if (key === TableKeyEnum.CROP) {
        return sortOrder === 'ASC' ? first.crop - second.crop : second.crop - first.crop;
      } else if (key === TableKeyEnum.NON_CROP) {
        return sortOrder === 'ASC' ? first.nonCrop - second.nonCrop : second.nonCrop - first.nonCrop;
      }
      return 0;
    };

    const sortedDataCropCover = formatArray?.sort((first: IRow, second: IRow) => sortFunction(first, second));

    if (sortedDataCropCover && sortedDataCropCover.length > 0) {
      return sortedDataCropCover.map((dataCropCover: any, index: number) => {
        const isDisplayRowCropCover = dataCropCover.cropCoverAnalysisSummary.some((col: any) => !!col.value);
        const sumCropCoverAnalysisSummary = dataCropCover.cropCoverAnalysisSummary.reduce(
          (total: number, cropCoverData: any) => (total += cropCoverData.value),
          0
        );

        return (
          <TableRow key={index}>
            {isDisplayRowCropCover && dataCropCover.landUseArea !== 0 && (
              <TableCellSummary>{dataCropCover.name}</TableCellSummary>
            )}
            <TableCellSummary>
              {clientAreaUnitMeasurement(dataCropCover.crop, areaSetting)} {`${areaUnit}`}
              <Typography>
                ({(Math.round((dataCropCover.crop / sumCropCoverAnalysisSummary) * 100 * 100) / 100).toFixed(2)} %)
              </Typography>
            </TableCellSummary>
            <TableCellSummary>
              {clientAreaUnitMeasurement(dataCropCover.nonCrop, areaSetting)} {`${areaUnit}`}
              <Typography>
                ({(Math.round((dataCropCover.nonCrop / sumCropCoverAnalysisSummary) * 100 * 100) / 100).toFixed(2)} %)
              </Typography>
            </TableCellSummary>
          </TableRow>
        );
      });
    }
    return <></>;
  }, [allLocationCropType, areaSetting, areaUnit, sortInfo]);

  const handleSortTable = (key: TableKeyEnum, sortOrder: TSortOrder) => {
    let newSortOrder: TSortOrder = null;
    if (!sortOrder) newSortOrder = 'DESC';
    if (sortOrder === 'ASC') newSortOrder = 'DESC';
    if (sortOrder === 'DESC') newSortOrder = 'ASC';
    setSortInfo({ key, sortOrder: newSortOrder });

    const newTableHeaders = tableHeaders.map((header) =>
      header.key === key ? { ...header, sortOrder: newSortOrder } : { ...header, sortOrder: null }
    );
    setTableHeaders(newTableHeaders);
  };

  const renderSortIcon = (sortOrder: TSortOrder) => {
    switch (sortOrder) {
      case 'ASC':
        return DescIcon;
      case 'DESC':
        return AscIcon;
      default:
        return SwapVertIcon;
    }
  };

  return (
    <TableContainer
      sx={{
        overflow: 'hidden',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '5px',
        mt: '-1px',
        mb: '12px',
      }}>
      <Table>
        <TableHead>
          {
            <TableRow
              component="tr"
              sx={{
                backgroundColor: (theme) => theme.palette.background.paper,
              }}>
              {tableHeaders.map((item) => (
                <TableCellHeaderSummary>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                    {t(item.label)}
                    <SvgIcon
                      component={renderSortIcon(item.sortOrder)}
                      inheritViewBox
                      sx={{ fontSize: '14px', cursor: 'pointer' }}
                      onClick={() => handleSortTable(item.key, item.sortOrder)}
                    />
                  </Box>
                </TableCellHeaderSummary>
              ))}
            </TableRow>
          }
        </TableHead>
        <TableBody
          sx={{
            pt: 0,
            flexDirection: 'column',
            minHeight: '510px',
            maxHeight: '510px',
            overflowY: 'scroll',
            '-ms-overflow-style': 'none' /* IE and Edge */,
            scrollbarWidth: 'none' /* Firefox */,
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}>
          {tableCropCoverage}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
