import { Box, InputLabel } from '@mui/material';
import { TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { clientSelector } from 'store/slices/clientSlice';
import { CropCoverTable } from './CropCoverTable';
import LandUseTable from './land-use-table';
import PaddyTable from './PaddyTable';
import StandCountTable from './StandCountTable';

const AnalyticsHigherLevels = () => {
  const { allLocationCropType } = useAppSelector(clientSelector);
  const { t } = useTranslation();

  const isDisplayCropCover = useMemo(() => {
    return allLocationCropType && allLocationCropType.some((item) => item.cropCoverAnalysisSummary?.length > 0);
  }, [allLocationCropType]);

  const isDisplayStandCountTable = useMemo(() => {
    return allLocationCropType && allLocationCropType.some((item) => item.standCountAnalysisSummary?.length > 0);
  }, [allLocationCropType]);

  const isDisplayLandUsetable = useMemo(() => {
    return allLocationCropType && allLocationCropType.some((item) => item.landUseAnalysisSummary?.length > 0);
  }, [allLocationCropType]);

  const arrayCustomFieldTable = useMemo(() => {
    if (!allLocationCropType?.length) return [];
    const customFieldAnalysisData = allLocationCropType?.map((item) => item?.customFieldAnalysisSummary).flat();
    let listCustomFieldRelabel = new Set<string>();
    customFieldAnalysisData.forEach((item) => {
      listCustomFieldRelabel.add(item.relabel);
    });
    return [...listCustomFieldRelabel];
  }, [allLocationCropType]);

  const arrayTypePaddyDisplayTable = useMemo(() => {
    const tempArray = [] as any;
    if (allLocationCropType) {
      if (allLocationCropType.some((item) => item.weedInvasionAnalysisSummary?.length > 0)) {
        tempArray.push(TYPE_ANALYTICS_MAP_VIEW.WEED_INVASION);
      }
      if (allLocationCropType.some((item) => item.plantHealthAnalysisSummary?.length > 0)) {
        tempArray.push(TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_FIELD);
      }
      if (allLocationCropType.some((item) => item.tillerDensityAnalysisSummary?.length > 0)) {
        tempArray.push(TYPE_ANALYTICS_MAP_VIEW.TILLER_DENSITY);
      }
      if (allLocationCropType.some((item) => item.vacantAreaAnalysisSummary?.length > 0)) {
        tempArray.push(TYPE_ANALYTICS_MAP_VIEW.VACANT_AREA);
      }
      if (allLocationCropType.some((item) => item.chlorophyllPaddyAnalysisSummary?.length > 0)) {
        tempArray.push(TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_FIELD);
      }
      if (allLocationCropType.some((item) => item.vigorPaddyAnalysisSummary?.length > 0)) {
        tempArray.push(TYPE_ANALYTICS_MAP_VIEW.VIGOR_FIELD);
      }
      if (allLocationCropType.some((item) => item.stressFieldAnalysisSummary?.length > 0)) {
        tempArray.push(TYPE_ANALYTICS_MAP_VIEW.STRESS_FIELD);
      }
      if (allLocationCropType.some((item) => item.waterUptakeFieldAnalysisSummary?.length > 0)) {
        tempArray.push(TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_FIELD);
      }
      if (allLocationCropType.some((item) => item.soilWaterContentFieldAnalysisSummary?.length > 0)) {
        tempArray.push(TYPE_ANALYTICS_MAP_VIEW.SOIL_WATER_CONTENT_FIELD);
      }
      if (allLocationCropType.some((item) => item.soilErosionFieldAnalysisSummary?.length > 0)) {
        tempArray.push(TYPE_ANALYTICS_MAP_VIEW.SOIL_EROSION_FIELD);
      }
      if (allLocationCropType.some((item) => item.leafPhenologyFieldAnalysisSummary?.length > 0)) {
        tempArray.push(TYPE_ANALYTICS_MAP_VIEW.LEAF_PHENOLOGY_FIELD);
      }
      if (allLocationCropType.some((item) => item.elevationFieldAnalysisSummary?.length > 0)) {
        tempArray.push(TYPE_ANALYTICS_MAP_VIEW.ELEVATION_FIELD);
      }
      if (allLocationCropType.some((item) => item.slopeFieldAnalysisSummary?.length > 0)) {
        tempArray.push(TYPE_ANALYTICS_MAP_VIEW.SLOPE_FIELD);
      }
      if (allLocationCropType.some((item) => item.vegetationEncroachmentAnalysisSummary?.length > 0)) {
        tempArray.push(TYPE_ANALYTICS_MAP_VIEW.VEGETATION_ENCROACHMENT);
      }
      if (allLocationCropType.some((item) => item.watershedBasinSummary?.length > 0)) {
        tempArray.push(TYPE_ANALYTICS_MAP_VIEW.WATERSHED_BASIN);
      }
    }
    return tempArray;
  }, [allLocationCropType]);

  return (
    <Box>
      {arrayTypePaddyDisplayTable.map((item: any, index: number) => {
        return (
          <Box className="paddy-table" key={index}>
            <PaddyTable nameTable={item} />
          </Box>
        );
      })}
      {arrayCustomFieldTable?.map((item, index) => {
        return (
          <Box className="paddy-table" key={index}>
            <PaddyTable nameTable={item} />
          </Box>
        );
      })}
      {isDisplayLandUsetable && (
        <Box className="landUse-table">
          <LandUseTable />
        </Box>
      )}
      {isDisplayCropCover && (
        <Box className="cropcover-table">
          <InputLabel sx={{ color: (theme) => theme.palette.primary.main }}>{t('mapView.crop_coverage')}</InputLabel>
          <CropCoverTable />
        </Box>
      )}
      {isDisplayStandCountTable && (
        <Box sx={{ py: 3 }}>
          <InputLabel sx={{ color: (theme) => theme.palette.primary.main }}>{t('mapView.stand_count')}</InputLabel>
          <StandCountTable />
        </Box>
      )}
    </Box>
  );
};
export default AnalyticsHigherLevels;
