import SwapVertIcon from '@mui/icons-material/SwapVert';
import {
  Box,
  InputLabel,
  SvgIcon,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import { AscIcon, DescIcon } from 'assets/icons';
import { BigNumber } from 'bignumber.js';
import { MeasurementUnit, SettingUnitName } from 'common/defines/clients';
import { ITableHeaders, TSortOrder, TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { defaultClientUnitSetting } from 'common/dummy/dummyClients';
import { convertTextToMultiLanguage } from 'common/utils/convert';
import { QUERY_KEY } from 'constants/constants';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getClientSettingsById } from 'services/clients/apiClient.services';
import { useAppSelector } from 'store/hooks';
import { clientSelector } from 'store/slices/clientSlice';
import { clientAreaUnitMeasurement } from '../IssuesTab/utils';
import TableCellHeaderSummary from './components/TableCellHeaderSummary';
import TableCellSummary from './components/TableCellSummary';

enum TableKeyEnum {
  NAME = 'Name',
  AREA = 'Area',
  PERCENTAGE = 'Percentage',
}

interface IRow {
  type: string;
  lduseArea: number;
}

const PaddyTable = ({ nameTable }: any) => {
  const { allLocationCropType } = useAppSelector(clientSelector);
  const { clientId } = useParams();
  const theme = useTheme();
  const { t } = useTranslation();

  const initialTableHeaders = [
    { key: TableKeyEnum.NAME, label: 'trans.size', sortOrder: null },
    { key: TableKeyEnum.AREA, label: 'trans.area', sortOrder: null },
    { key: TableKeyEnum.PERCENTAGE, label: 'trans.percentage', sortOrder: null },
  ];
  const [tableHeaders, setTableHeaders] = useState<ITableHeaders<TableKeyEnum>[]>(initialTableHeaders);
  const [sortInfo, setSortInfo] = useState<{ key: TableKeyEnum; sortOrder: TSortOrder }>({
    key: TableKeyEnum.NAME,
    sortOrder: null,
  });

  const formatData = (data: any[]) => {
    const helper = {} as any;
    const result = data.reduce((pre, current) => {
      const key = current.type;

      if (!helper[key]) {
        helper[key] = Object.assign({}, current);
        pre.push(helper[key]);
      } else {
        helper[key].lduseArea += current.lduseArea;
        helper[key].percentage += current.ldusePercentage;
      }

      return pre;
    }, []);
    return result;
  };

  const generatePercent = (data: any[]) => {
    const sumArea = data
      .map((_item: any) => _item.lduseArea)
      .reduce((previousValue: number, currentValue: number) => new BigNumber(previousValue).plus(currentValue), 0);
    let sumPercent = new BigNumber(0);

    const dataPercent = data.map((_item: any, index: number) => {
      if (index === data.length - 1) {
        return {
          ..._item,
          percent: new BigNumber(100).minus(sumPercent).toNumber(),
        };
      }
      const percent = new BigNumber(_item.lduseArea).div(sumArea).multipliedBy(100).toFixed(2);
      sumPercent = new BigNumber(percent).plus(sumPercent);
      return {
        ..._item,
        percent: +percent,
      };
    });
    return dataPercent;
  };

  const summaryDataAnalysis = (data: any[]) => {
    if (data.length > 0) {
      const result = formatData(data);
      const dataPercent = generatePercent(result);
      return dataPercent;
    }
    return [];
  };

  const { data: clientSetting } = useQuery(
    [QUERY_KEY.CLIENT_SETTINGS_BY_ID, clientId],
    () => getClientSettingsById(clientId || ''),
    {
      enabled: !!clientId,
    }
  );

  const areaSetting = useMemo(() => {
    return (
      clientSetting?.unitSetting?.find((data: any) => data.name === SettingUnitName.AREA) || defaultClientUnitSetting[0]
    );
  }, [clientSetting]);

  const renderSortIcon = (sortOrder: TSortOrder) => {
    switch (sortOrder) {
      case 'ASC':
        return DescIcon;
      case 'DESC':
        return AscIcon;
      default:
        return SwapVertIcon;
    }
  };

  const areaUnit = useMemo(() => {
    const unitArea = areaSetting.unit;
    if (unitArea === MeasurementUnit.M2) {
      return 'm²';
    }
    if (unitArea === MeasurementUnit.KM2) {
      return 'km²';
    }
    return unitArea;
  }, [areaSetting]);

  const tableArea = useMemo(() => {
    if (allLocationCropType && allLocationCropType.length > 0) {
      const mergeArray = allLocationCropType.reduce((pre: any, curent: any) => {
        switch (nameTable) {
          case TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_FIELD:
            return pre.concat(curent.plantHealthAnalysisSummary);
          case TYPE_ANALYTICS_MAP_VIEW.WEED_INVASION:
            return pre.concat(curent.weedInvasionAnalysisSummary);
          case TYPE_ANALYTICS_MAP_VIEW.VACANT_AREA:
            return pre.concat(curent.vacantAreaAnalysisSummary);
          case TYPE_ANALYTICS_MAP_VIEW.TILLER_DENSITY:
            return pre.concat(curent.tillerDensityAnalysisSummary);
          case TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_FIELD:
            return pre.concat(curent.chlorophyllPaddyAnalysisSummary);
          case TYPE_ANALYTICS_MAP_VIEW.VIGOR_FIELD:
            return pre.concat(curent.vigorPaddyAnalysisSummary);
          case TYPE_ANALYTICS_MAP_VIEW.STRESS_FIELD:
            return pre.concat(curent.stressFieldAnalysisSummary);
          case TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_FIELD:
            return pre.concat(curent.waterUptakeFieldAnalysisSummary);
          case TYPE_ANALYTICS_MAP_VIEW.SOIL_WATER_CONTENT_FIELD:
            return pre.concat(curent.soilWaterContentFieldAnalysisSummary);
          case TYPE_ANALYTICS_MAP_VIEW.SOIL_EROSION_FIELD:
            return pre.concat(curent.soilErosionFieldAnalysisSummary);
          case TYPE_ANALYTICS_MAP_VIEW.LEAF_PHENOLOGY_FIELD:
            return pre.concat(curent.leafPhenologyFieldAnalysisSummary);
          case TYPE_ANALYTICS_MAP_VIEW.ELEVATION_FIELD:
            return pre.concat(curent.elevationFieldAnalysisSummary);
          case TYPE_ANALYTICS_MAP_VIEW.SLOPE_FIELD:
            return pre.concat(curent.slopeFieldAnalysisSummary);
          case TYPE_ANALYTICS_MAP_VIEW.VEGETATION_ENCROACHMENT:
            return pre.concat(curent.vegetationEncroachmentAnalysisSummary);
          case TYPE_ANALYTICS_MAP_VIEW.WATERSHED_BASIN:
            return pre.concat(curent.watershedBasinSummary);
          // for custom field case
          default:
            return pre.concat(curent.customFieldAnalysisSummary?.filter((item: any) => item?.relabel === nameTable));
          // code block
        }
      }, []);
      const formatArray = summaryDataAnalysis(mergeArray);

      const totalArea = formatArray.reduce((pre: any, current: any) => {
        return pre + current.lduseArea;
      }, 0);

      const totalAreaPercent = formatArray.reduce((pre: any, current: any) => {
        return new BigNumber(pre).plus(current.percent);
      }, 0);

      const sortFunction = (first: IRow, second: IRow) => {
        const { key, sortOrder } = sortInfo;
        if (key === TableKeyEnum.NAME) {
          return sortOrder === 'ASC' ? first.type?.localeCompare(second.type) : second.type?.localeCompare(first.type);
        } else if (key === TableKeyEnum.AREA || key === TableKeyEnum.PERCENTAGE) {
          return sortOrder === 'ASC' ? first.lduseArea - second.lduseArea : second.lduseArea - first.lduseArea;
        }
        return 0;
      };

      const sortedArray = formatArray.sort((a: any, b: any) => sortFunction(a, b));

      const withoutConvertMultiLanguageAnalyticTableLabel = [
        TYPE_ANALYTICS_MAP_VIEW.ELEVATION_FIELD,
        TYPE_ANALYTICS_MAP_VIEW.WATERSHED_BASIN,
      ];

      const withoutConvertLabel = withoutConvertMultiLanguageAnalyticTableLabel.includes(nameTable);

      if (sortedArray && sortedArray.length > 0) {
        return (
          <>
            {sortedArray.map((item: any, index: number) => {
              return (
                item.lduseArea > 0 && (
                  <TableRow key={index}>
                    <TableCellSummary component="td">
                      {withoutConvertLabel ? item.type : t(convertTextToMultiLanguage(item.type, 'mapView'))}
                    </TableCellSummary>
                    <TableCellSummary component="td">
                      {clientAreaUnitMeasurement(item.lduseArea, areaSetting)}
                    </TableCellSummary>
                    <TableCellSummary component="td">{item.percent} %</TableCellSummary>
                  </TableRow>
                )
              );
            })}
            <TableRow>
              <TableCellSummary component="th" isBold>
                {t('trans.total')}
              </TableCellSummary>
              <TableCellSummary component="th" isBold>
                {clientAreaUnitMeasurement(totalArea, areaSetting)}
              </TableCellSummary>
              <TableCellSummary component="th" isBold>
                {totalAreaPercent.toNumber()} %
              </TableCellSummary>
            </TableRow>
          </>
        );
      }
    }
  }, [allLocationCropType, areaSetting, nameTable, sortInfo, summaryDataAnalysis, t]);

  const handleSortTable = (key: TableKeyEnum, sortOrder: TSortOrder) => {
    let newSortOrder: TSortOrder = null;
    if (!sortOrder) newSortOrder = 'DESC';
    if (sortOrder === 'ASC') newSortOrder = 'DESC';
    if (sortOrder === 'DESC') newSortOrder = 'ASC';
    setSortInfo({ key, sortOrder: newSortOrder });

    const newTableHeaders = tableHeaders.map((header) =>
      header.key === key ? { ...header, sortOrder: newSortOrder } : { ...header, sortOrder: null }
    );
    setTableHeaders(newTableHeaders);
  };

  return (
    <>
      <>
        <InputLabel sx={{ color: (theme) => theme.palette.primary.main }}>
          {`${t(convertTextToMultiLanguage(nameTable || 'custom_field', 'analytic'))} (${t('trans.area')})`}
        </InputLabel>
        <TableContainer
          sx={{
            overflow: 'hidden',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '5px',
            mt: '-1px',
            mb: '12px',
          }}>
          <Table>
            <TableHead>
              {
                <TableRow
                  component="tr"
                  sx={{
                    // backgroundColor: '#EDF1f1',
                    backgroundColor: theme.palette.background.paper,
                  }}>
                  {tableHeaders.map((item) => (
                    <TableCellHeaderSummary key={item.key} component="th">
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                        {`${t(item.label)} ${item.key === TableKeyEnum.AREA ? `(${areaUnit})` : ''}`}
                        <SvgIcon
                          component={renderSortIcon(item.sortOrder)}
                          inheritViewBox
                          sx={{ fontSize: '14px', cursor: 'pointer' }}
                          onClick={() => handleSortTable(item.key, item.sortOrder)}
                        />
                      </Box>
                    </TableCellHeaderSummary>
                  ))}
                </TableRow>
              }
            </TableHead>
            <TableBody
              sx={{
                pt: 0,
                flexDirection: 'column',
                minHeight: '510px',
                maxHeight: '510px',
                overflowY: 'scroll',
                '-ms-overflow-style': 'none' /* IE and Edge */,
                scrollbarWidth: 'none' /* Firefox */,
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}>
              {tableArea}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </>
  );
};

export default PaddyTable;
